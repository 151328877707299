import React, { memo, useState, useEffect } from "react";
import moment from "moment";
import { UploadFile } from ".";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { TIME_FORMATES, USER_ROLES } from "../constant";
import { MessageDropDown, VoiceMessage, EmojiCode } from ".";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SingleMessage = ({
  msg,
  number,
  messageRef,
  editMessage,
  handleMessage,
  messagesArray,
  handleAutoScroll,
  handleSaveMessage,
  setEditMessageText,
  editMessageLoading,
  deleteMessageLoading,
  setEditMessage,
}) => {
  const { userData } = useSelector((state) => state.userReducer);
  const { conversationById } = useSelector((state) => state.chatReducer);
  const { appSettings } = useSelector((state) => state.applicationReducer);

  useEffect(() => {
    if (messageRef?.current) {
      const textLength = messageRef.current.value.length;
      messageRef.current.selectionStart = textLength;
      messageRef.current.selectionEnd = textLength;
      messageRef.current.focus();
    }
  }, [messageRef, editMessage]);

  const DeletedMessage = () => {
    return (
      <div
        className={
          msg?.senderId?._id === userData?.data?._id
            ? "message message-out mt-5"
            : "message mt-5"
        }
      >
        <a
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#modal-user-profile"
          className="avatar avatar-responsive"
        >
          {msg?.senderId?.picture ? (
            <img
              className="avatar-img round_avatar"
              src={msg?.senderId?.picture}
              alt=""
            />
          ) : (
            <span className="avatar-text">
              {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
            </span>
          )}
        </a>
        <div className="message-inner">
          <div className="message-body">
            <div className="message-content">
              <div className="message-text">
                <i
                  className="fal fa-ban"
                  style={{
                    color: "rgba(255,0,0,0.9)",
                    marginRight: "10px",
                  }}
                />
                This message was deleted
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (msg?.is_delete) {
    return <DeletedMessage />;
  } else {
    switch (msg?.type) {
      case "message":
        return (
          <React.Fragment>
            <div
              className={
                conversationById?.recipients?.some((x) => {
                  return x?.user_role === USER_ROLES?.CLIENT;
                })
                  ? msg?.senderId?._id === userData?.data?._id ||
                    [
                      USER_ROLES?.SUPER_OPERATOR,
                      USER_ROLES?.OPERATOR,
                      USER_ROLES?.JUNIOR_OPERATOR,
                    ]?.includes(msg?.senderId?.user_role)
                    ? "message mt-5 message-out"
                    : "message mt-5 message"
                  : msg?.senderId?._id === userData?.data?._id
                  ? "message mt-5 message-out"
                  : "message mt-5 message"
              }
            >
              {msg?.senderId?._id !==
              messagesArray[number + 1]?.senderId?._id ? (
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target={
                    msg?.senderId?._id === userData?.data?._id
                      ? "#modal-profile"
                      : "#modal-user-profile"
                  }
                  className="avatar avatar-responsive"
                >
                  {msg?.senderId?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={msg?.senderId?.picture}
                      alt=""
                    />
                  ) : (
                    <span className="avatar-text">
                      {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
                    </span>
                  )}
                </a>
              ) : (
                <span className="avatar avatar-responsive" />
              )}

              <div className="message-inner">
                <div className="message-body">
                  <div className="message-content">
                    <div
                      className="message-text"
                      style={{
                        marginLeft: editMessage === msg?._id ? "15px" : "",
                      }}
                    >
                      {editMessage === msg?._id ? (
                        <div className="message-bar-quill">
                          <ReactQuill
                            defaultValue={msg?.text}
                            onChange={(value) => setEditMessageText(value)}
                            className="edit_message_input"
                            ref={messageRef}
                            rows={4}
                          />
                        </div>
                      ) : (
                        <p
                          className="a-tag-color"
                          style={{
                            wordWrap: "break-word",
                            marginBottom: "0px",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: msg?.text,
                            }}
                          />
                        </p>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span className="extra-small message-footer">
                          {moment(msg?.updatedAt).format(
                            appSettings?.application_time_format ===
                              TIME_FORMATES[0]?.format
                              ? "LT"
                              : "HH:mm"
                          )}
                        </span>
                        {editMessage === msg?._id ? (
                          <div style={{ display: "flex", gap: "10px" }}>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                color: "white",
                                border: "1px solid #fff",
                                fontSize: "12px",
                              }}
                              onClick={() => setEditMessage("")}
                            >
                              Cancel
                            </button>
                            <button
                              style={{
                                outline: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                backgroundColor: "#fff",
                                color: "black",
                                border: "1px solid #fff",
                                fontSize: "12px",
                              }}
                              onClick={() => handleSaveMessage()}
                            >
                              Save
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="message-action">
                      {editMessageLoading?.loading &&
                      editMessageLoading?.id === msg?._id ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <React.Fragment>
                          {editMessage === msg?._id ? null : (
                            <MessageDropDown
                              messageObj={msg}
                              messageId={msg?._id}
                              handleMessage={handleMessage}
                              senderId={msg?.senderId?._id}
                              deleteMessageLoading={deleteMessageLoading}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case "reply":
        return (
          <React.Fragment>
            <div
              className={
                conversationById?.recipients?.some((x) => {
                  return x?.user_role === USER_ROLES?.CLIENT;
                })
                  ? msg?.senderId?._id === userData?.data?._id ||
                    [
                      USER_ROLES?.SUPER_OPERATOR,
                      USER_ROLES?.OPERATOR,
                      USER_ROLES?.JUNIOR_OPERATOR,
                    ]?.includes(msg?.senderId?.user_role)
                    ? "message mt-5 message-out"
                    : "message mt-5 message"
                  : msg?.senderId?._id === userData?.data?._id
                  ? "message mt-5 message-out"
                  : "message mt-5 message"
              }
            >
              {msg?.senderId?._id !==
              messagesArray[number + 1]?.senderId?._id ? (
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target={
                    msg?.senderId?._id === userData?.data?._id
                      ? "#modal-profile"
                      : "#modal-user-profile"
                  }
                  className="avatar avatar-responsive"
                >
                  {msg?.senderId?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={msg?.senderId?.picture}
                      alt=""
                    />
                  ) : (
                    <span className="avatar-text">
                      {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
                    </span>
                  )}
                </a>
              ) : (
                <span className="avatar avatar-responsive" />
              )}
              <div className="message-inner">
                <div className="message-body">
                  <div className="message-content">
                    <div
                      className="message-text"
                      style={{
                        marginLeft: editMessage === msg?._id ? "15px" : "",
                      }}
                    >
                      <blockquote className="blockquote overflow-hidden">
                        <h6 className="text-reset text-truncate">
                          {
                            messagesArray?.find((x) => {
                              return x._id === msg?.reply_of_message;
                            })?.senderId?.name
                          }
                        </h6>
                        <p className="small text-truncate">
                          {messagesArray?.find((x) => {
                            return x._id === msg?.reply_of_message;
                          })?.type === "message" ||
                          messagesArray?.find((x) => {
                            return x._id === msg?.reply_of_message;
                          })?.type === "reply" ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: messagesArray?.find((x) => {
                                  return x._id === msg?.reply_of_message;
                                })?.text,
                              }}
                            />
                          ) : messagesArray?.find((x) => {
                              return x._id === msg?.reply_of_message;
                            })?.type === "images" ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "100px",
                                height: "100px",
                                backgroundColor: "transparent",
                              }}
                            >
                              <img
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                src={
                                  messagesArray?.find((x) => {
                                    return x._id === msg?.reply_of_message;
                                  })?.document[0]?.location
                                }
                              />
                            </div>
                          ) : messagesArray?.find((x) => {
                              return x._id === msg?.reply_of_message;
                            })?.type === "files" ? (
                            <>
                              <i className="fal fa-file" />{" "}
                              {
                                messagesArray?.find((x) => {
                                  return x._id === msg?.reply_of_message;
                                })?.document[0]?.originalname
                              }
                            </>
                          ) : messagesArray?.find((x) => {
                              return x._id === msg?.reply_of_message;
                            })?.type === "voice" ? (
                            <React.Fragment>
                              Audio{" "}
                              <i
                                className="fal fa-music"
                                style={{ marginLeft: 5, fontSize: 10 }}
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </p>
                      </blockquote>
                      {editMessage === msg?._id ? (
                        <div className="message-bar-quill">
                          <ReactQuill
                            defaultValue={msg?.text}
                            onChange={(value) => setEditMessageText(value)}
                            className="edit_message_input"
                            ref={messageRef}
                            rows={4}
                          />
                        </div>
                      ) : (
                        <p
                          style={{
                            wordWrap: "break-word",
                            marginBottom: "0px",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: msg?.text }}
                          />
                        </p>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span className="extra-small message-footer">
                          {moment(msg?.updatedAt).format(
                            appSettings?.application_time_format ===
                              TIME_FORMATES[0]?.format
                              ? "LT"
                              : "HH:mm"
                          )}
                        </span>
                        {editMessage === msg?._id ? (
                          <div style={{ display: "flex", gap: "10px" }}>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                color: "white",
                                border: "1px solid #fff",
                                fontSize: "12px",
                              }}
                              onClick={() => setEditMessage("")}
                            >
                              Cancel
                            </button>
                            <button
                              style={{
                                outline: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                backgroundColor: "#fff",
                                color: "black",
                                border: "1px solid #fff",
                                fontSize: "12px",
                              }}
                              onClick={() => handleSaveMessage()}
                            >
                              Save
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="message-action">
                      {editMessageLoading?.loading &&
                      editMessageLoading?.id === msg?._id ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <React.Fragment>
                          {editMessage === msg?._id ? (
                            <span
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Save message"
                            >
                              <i
                                className="fal fa-save"
                                onClick={() => handleSaveMessage()}
                              />
                            </span>
                          ) : (
                            <MessageDropDown
                              senderId={msg?.senderId?._id}
                              handleMessage={handleMessage}
                              messageId={msg?._id}
                              messageObj={msg}
                              deleteMessageLoading={deleteMessageLoading}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case "files":
      case "images":
        return (
          <React.Fragment>
            <UploadFile
              msg={msg}
              number={number}
              handleMessage={handleMessage}
              messagesArray={messagesArray}
              handleAutoScroll={handleAutoScroll}
            />
          </React.Fragment>
        );
      case "notification":
        return <p>notification</p>;
      case "voice":
        return (
          <div
            className={
              conversationById?.recipients?.some((x) => {
                return x?.user_role === USER_ROLES?.CLIENT;
              })
                ? msg?.senderId?._id === userData?.data?._id ||
                  [
                    USER_ROLES?.SUPER_OPERATOR,
                    USER_ROLES?.OPERATOR,
                    USER_ROLES?.JUNIOR_OPERATOR,
                  ]?.includes(msg?.senderId?.user_role)
                  ? "message mt-5 message-out"
                  : "message mt-5 message"
                : msg?.senderId?._id === userData?.data?._id
                ? "message mt-5 message-out"
                : "message mt-5 message"
            }
          >
            {msg?.senderId?._id !== messagesArray[number + 1]?.senderId?._id ? (
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target={
                  msg?.senderId?._id === userData?.data?._id
                    ? "#modal-profile"
                    : "#modal-user-profile"
                }
                className="avatar avatar-responsive"
              >
                {msg?.senderId?.picture ? (
                  <img
                    className="avatar-img round_avatar"
                    src={msg?.senderId?.picture}
                    alt=""
                  />
                ) : (
                  <span className="avatar-text">
                    {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
                  </span>
                )}
              </a>
            ) : (
              <span className="avatar avatar-responsive" />
            )}
            <div className="message-inner">
              <div className="message-body">
                <div className="message-content">
                  <VoiceMessage voice={msg?.document[0]?.location} />
                  <MessageDropDown
                    senderId={msg?.senderId?._id}
                    handleMessage={handleMessage}
                    messageId={msg?._id}
                    messageObj={msg}
                    deleteMessageLoading={deleteMessageLoading}
                  />
                </div>
              </div>
              <div className="message-footer">
                <span className="extra-small text-muted">
                  {moment(msg?.createdAt).format(
                    appSettings?.application_time_format ===
                      TIME_FORMATES[0]?.format
                      ? "LT"
                      : "HH:mm"
                  )}
                </span>
              </div>
            </div>
          </div>
        );
      default:
        return <p></p>;
    }
  }
};
export default memo(SingleMessage);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import InputEmoji from "react-input-emoji";
import { useNavigate } from "react-router-dom";
import { getFileTypes } from "../utils/fileTypes";
import {
  ImageSelector,
  DocumentSelector,
  ToastContainer as Toaster,
  ResponseMessages,
} from ".";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EmojiPicker from "emoji-picker-react";

const toolbar = [
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["link"],
  ["clean"],
];

const MessageBar = ({
  inputRef,
  userById,
  fileType,
  startCall,
  setDocument,
  setFileType,
  replyMessage,
  callPageLink,
  screenSharing,
  showVideoCall,
  showVoiceCall,
  currentMessage,
  setMessageType,
  handleNavigate,
  conversationId,
  setReplyMessage,
  setCurrentMessage,
  handleMessageType,
  setOpenVoiceModal,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showResponses, setShowResponses] = useState(false);
  const [responseCode, setResponseCode] = useState([]);

  const { appSettings } = useSelector((state) => state.applicationReducer);

  const reactQuillRef = useRef(null);
  const emojiPickerRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (reactQuillRef.current) {
      reactQuillRef.current?.getEditor()?.focus();
    }
  }, [showEmojiPicker, emojiPickerRef, reactQuillRef]);

  useEffect(() => {
    setShowResponses(currentMessage?.includes("%"));
    setResponseCode(
      currentMessage
        ?.replace(/<\/?[^>]+>/g, " ")
        ?.split(" ")
        ?.filter((item) => {
          return item?.includes("%");
        })
    );
  }, [currentMessage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMessageBar = () => {
    setReplyMessage(null);
    setMessageType("message");
  };

  const handleInputValue = (inputValue) => {
    setCurrentMessage(inputValue);
  };

  const handleEmojiClick = ({ emoji }) => {
    const quill = reactQuillRef.current.getEditor();
    quill.focus();
    const range = quill.getSelection();
    if (range) {
      quill.insertText(range.index, emoji, "user");
      quill.setSelection(range?.index + emoji?.length);
    } else {
      const currentMessage = quill.getContents();
      quill.setContents(currentMessage.concat(emoji));
    }
  };

  const handleCall = (type) => {
    if (!sessionStorage.getItem("streamCheck")) {
      if (appSettings?.call_api_key && appSettings?.call_secret_key) {
        sessionStorage.setItem("streamCheck", type);
        startCall(type);
      } else {
        Toaster({
          success: false,
          message: "Please add call API key in App Settings",
        });
      }
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (
        currentMessage !== "" &&
        currentMessage !== "<p></p>" &&
        currentMessage !== "<p><br></p>" &&
        currentMessage !== "<p><br/></p>" &&
        currentMessage !== "<p><br></p><p><br></p>"
      ) {
        await handleMessageType();
      }
      setCurrentMessage("");
    }
  };

  return (
    <React.Fragment>
      <div className="mt-5 pb-5 bottom-0 start-0 row">
        <div
          className="dz-preview bg-dark"
          id="dz-preview-row"
          data-horizontal-scroll=""
        />
        <div
          className="message-bar-quill bg-dark"
          style={{
            borderRadius: 20,
            border:
              localStorage.getItem("theme") === "light"
                ? "1px solid #d4d4d4"
                : "1px solid #4a4a4a",
            padding: 0,
            position: "relative",
          }}
        >
          <ResponseMessages
            show={showResponses}
            responseCode={responseCode}
            setCurrentMessage={setCurrentMessage}
            currentMessage={currentMessage}
            inputRef={inputRef}
          />
          {replyMessage !== null && replyMessage ? (
            <div className="message-text row mx-1" style={{ borderRadius: 20 }}>
              <blockquote className="blockquote col">
                <h6 className="text-reset text-truncate">
                  {replyMessage?.senderId?.name}
                </h6>
                <p
                  className="small text-truncate col hide-scrollbar"
                  style={{
                    maxHeight: 200,
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  {replyMessage?.type === "message" ||
                  replyMessage?.type === "reply" ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: replyMessage?.text }}
                    />
                  ) : replyMessage?.type === "images" ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "transparent",
                      }}
                    >
                      <img
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        src={replyMessage?.document[0]?.location}
                      />
                    </div>
                  ) : replyMessage?.type === "files" ? (
                    replyMessage?.document[0]?.originalname
                  ) : (
                    <React.Fragment>
                      Audio{" "}
                      <i
                        className="fal fa-music"
                        style={{ marginLeft: 5, fontSize: 10 }}
                      />
                    </React.Fragment>
                  )}
                </p>
              </blockquote>
              <i
                className="fal fa-times col-auto"
                style={{ cursor: "pointer" }}
                onClick={handleMessageBar}
              />
            </div>
          ) : null}
          <div
            className="mb-2"
            style={{ position: "relative" }}
            ref={emojiPickerRef}
          >
            <EmojiPicker
              style={{ position: "absolute", bottom: 0, left: 0, zIndex: 1000 }}
              theme={localStorage.getItem("theme")}
              open={showEmojiPicker}
              onEmojiClick={handleEmojiClick}
              autoFocusSearch={false}
            />
          </div>
          <ReactQuill
            ref={reactQuillRef}
            modules={{
              toolbar,
            }}
            type="number"
            placeholder={"Message..."}
            value={currentMessage}
            onChange={handleInputValue}
            defaultValue={""}
            onKeyDown={handleKeyDown}
          />
          <div
            className=""
            style={{
              height: 50,
              display: "flex",
              alignItems: "center",
              padding: "10px",
              paddingLeft: "15px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", gap: 15, position: "relative" }}
              className="message-bar-quill-icons"
            >
              <ImageSelector
                setDocument={setDocument}
                setFileType={setFileType}
                selectedFileTypes={appSettings?.application_file_types}
              />
              <DocumentSelector
                setDocument={setDocument}
                setFileType={setFileType}
                selectedFileTypes={appSettings?.application_file_types}
              />
              <i
                className="fal fa-microphone-alt"
                onClick={() => setOpenVoiceModal(true)}
              />
              {showVoiceCall && (
                <i
                  className="fal fa-headset"
                  onClick={() => handleCall("v-o-i-c-e")}
                />
              )}
              {showVideoCall && (
                <i
                  className="fal fa-video"
                  onClick={() => handleCall("s-t-r-e-a-m")}
                />
              )}
              <i
                className="fal fa-smile"
                style={{ position: "relative" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEmojiPicker(!showEmojiPicker);
                }}
              />
            </div>
            <button
              className="btn-primary message-bar-quill-send-btn"
              style={{
                height: 30,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                outline: "none",
                border: "none",
              }}
              onClick={() => {
                if (
                  currentMessage !== "" &&
                  currentMessage !== "<p></p>" &&
                  currentMessage !== "<p><br></p>" &&
                  currentMessage !== "<p><br/></p>" &&
                  currentMessage !== "<p><br></p><p><br></p>"
                ) {
                  handleMessageType();
                }
              }}
            >
              <i className="fal fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
      {/* <div className="chat-footer mt-3 pb-3 pb-lg-7 bottom-0 start-0">
        <div
          className="dz-preview bg-dark"
          id="dz-preview-row"
          data-horizontal-scroll=""
        ></div>
        <div
          className="chat-form rounded-pill bg-dark"
          style={{ position: "relative" }}
        >
          <ResponseMessages
            show={showResponses}
            responseCode={responseCode}
            setCurrentMessage={setCurrentMessage}
            currentMessage={currentMessage}
            inputRef={inputRef}
          />
          <div className="row align-items-center gx-0">
            <div className="col-auto">
              <div
                className="dropdown"
                style={{
                  transform: "translate(5px, 0px)",
                }}
              >
                <a
                  className="icon icon-lg text-muted ms-5"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fal fa-ellipsis-v-alt fa-lg"></i>
                </a>
                <ul className="dropdown-menu">
                  <ImageSelector
                    setDocument={setDocument}
                    setFileType={setFileType}
                    selectedFileTypes={appSettings?.application_file_types}
                  />
                  <DocumentSelector
                    setDocument={setDocument}
                    setFileType={setFileType}
                    selectedFileTypes={appSettings?.application_file_types}
                  />
                  <li>
                    <button
                      className="dropdown-item d-flex align-items-center"
                      onClick={() => setOpenVoiceModal(true)}
                    >
                      Voice Message&nbsp;
                      <div className="icon ms-auto">
                        <i className="fal fa-microphone-alt"></i>
                      </div>
                    </button>
                  </li>
                  {showVoiceCall && (
                    <li>
                      <button
                        className="dropdown-item d-flex align-items-center"
                        disabled={sessionStorage.getItem("streamCheck")}
                        onClick={() => {
                          if (
                            appSettings?.call_api_key &&
                            appSettings?.call_secret_key
                          ) {
                            sessionStorage.setItem("streamCheck", "v-o-i-c-e");
                            startCall("v-o-i-c-e");
                          } else {
                            Toaster({
                              success: false,
                              message:
                                "Please add call API key in App Settings",
                            });
                          }
                        }}
                      >
                        Voice Call
                        <div className="icon ms-auto">
                          <i className="fal fa-headset"></i>
                        </div>
                      </button>
                    </li>
                  )}
                  {showVideoCall && (
                    <li>
                      <button
                        className="dropdown-item d-flex align-items-center"
                        disabled={sessionStorage.getItem("streamCheck")}
                        onClick={() => {
                          if (
                            appSettings?.call_api_key &&
                            appSettings?.call_secret_key
                          ) {
                            sessionStorage.setItem(
                              "streamCheck",
                              "s-t-r-e-a-m"
                            );
                            startCall("s-t-r-e-a-m");
                          } else {
                            Toaster({
                              success: false,
                              message:
                                "Please add call API key in App Settings",
                            });
                          }
                        }}
                      >
                        Video Call
                        <div className="icon ms-auto">
                          <i className="fal fa-video"></i>
                        </div>
                      </button>
                    </li>
                  )}
                  {screenSharing && (
                    <li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <button className="dropdown-item d-flex align-items-center">
                        Screen Sharing
                        <div className="icon ms-auto">
                          <i className="fal fa-desktop-alt"></i>
                        </div>
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col">
              {replyMessage !== null && replyMessage && (
                <div
                  className="message-text"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <blockquote className="blockquote overflow-hidden">
                    <h6 className="text-reset text-truncate">
                      {replyMessage?.senderId?.name}
                    </h6>
                    <p className="small text-truncate">
                      {replyMessage?.type === "message" ||
                      replyMessage?.type === "reply" ? (
                        replyMessage?.text
                      ) : replyMessage?.type === "images" ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            width: "100px",
                            height: "100px",
                            backgroundColor: "transparent",
                          }}
                        >
                          <img
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                            src={replyMessage?.document[0]?.location}
                          />
                        </div>
                      ) : replyMessage?.type === "files" ? (
                        replyMessage?.document[0]?.originalname
                      ) : (
                        <React.Fragment>
                          Audio{" "}
                          <i
                            className="fal fa-music"
                            style={{ marginLeft: 5, fontSize: 10 }}
                          />
                        </React.Fragment>
                      )}
                    </p>
                  </blockquote>
                  <i
                    className="fal fa-times"
                    style={{ cursor: "pointer" }}
                    onClick={handleMessageBar}
                  />
                </div>
              )}
              <div
                className="input-group"
                style={{
                  marginLeft: 5,
                }}
              >
                <InputEmoji
                  cleanOnEnter
                  ref={inputRef}
                  value={currentMessage}
                  onEnter={handleMessageType}
                  onChange={handleInputValue}
                  placeholder="Type your message..."
                  theme={localStorage.getItem("theme")}
                  buttonElement={<button>0</button>}
                />
              </div>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-icon btn-primary rounded-circle ms-5"
                onClick={handleMessageType}
              >
                <i className="fal fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};
export default memo(MessageBar);

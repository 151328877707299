import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "../../assets/img/core/card-header.png";
import { addBot, editBot } from "../../redux/actions/botAction";
import { ToastContainer, ReactQuill, MultipleSelect } from "../../components";

let REQUIRED_FIELD = `Required field`;

const BotModal = ({ setModalShow, modalShow }) => {
  const [quillValue, setQuillValue] = useState("");
  const [addBotLoading, setAddBotLoading] = useState(false);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [selectedBot, setSelectedBot] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (quillValue === "<p><br></p>") setQuillValue("");
  }, [quillValue]);

  const { appSettings } = useSelector((state) => state.applicationReducer);
  const { botObj, allBots } = useSelector((state) => state.botReducer);
  const { allDepartments } = useSelector((state) => state.departmentReducer);
  const { allWidgets } = useSelector((state) => state.widgetReducer);

  useEffect(() => {
    if (botObj?.purpose === "edit") {
      setSelectedBot(
        allBots?.find((x) => {
          return x?._id === botObj?.botId;
        })
      );
    } else {
      setSelectedBot({});
    }
  }, [botObj]);

  useEffect(() => {
    if (botObj?.purpose === "edit") {
      setQuillValue(selectedBot?.bot_message);
    }
  }, [selectedBot, botObj]);

  useEffect(() => {
    if (botObj?.purpose !== "edit") {
      setQuillValue("");
    }
  }, [botObj]);

  let INITIAL_VALUE = {
    keyword: "",
    title: "",
    bot_message: "",
    widgets: "",
    department: "",
    language: "",
    is_engage_active: false,
    is_connect_faq: false,
    is_show_keywords: true,
  };

  let editedValues = {
    keyword: selectedBot?.keyword,
    title: selectedBot?.title,
    bot_message: selectedBot?.bot_message,
    widgets: selectedBot?.widgets,
    department: selectedBot?.department?._id,
    language: selectedBot?.language,
    is_engage_active: selectedBot?.is_engage_active,
    is_connect_faq: selectedBot?.is_connect_faq,
    is_show_keywords: selectedBot?.is_show_keywords,
  };

  useEffect(() => {
    if (botObj?.purpose === "edit") setSelectedWidgets(selectedBot?.widgets);
  }, [selectedBot]);

  useEffect(() => {
    if (botObj?.purpose === "addNew") setSelectedWidgets([]);
  }, [botObj]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: botObj?.purpose === "edit" ? editedValues : INITIAL_VALUE,
    validationSchema: yup.object({
      keyword: yup
        .string(REQUIRED_FIELD)
        .min(3, "Keyword must be 3 characters long")
        .required(REQUIRED_FIELD),
      title: yup
        .string(REQUIRED_FIELD)
        .min(3, "Title must be 3 characters long")
        .required(REQUIRED_FIELD),
      bot_message: yup
        .string(REQUIRED_FIELD)
        .min(12, "Message must be 5 characters long")
        .required(REQUIRED_FIELD),
      widgets: yup
        .array(REQUIRED_FIELD)
        .of(yup.string().required(REQUIRED_FIELD))
        .min(1, REQUIRED_FIELD)
        .required(REQUIRED_FIELD),
      department: yup.string(REQUIRED_FIELD).required(REQUIRED_FIELD),
      language: yup.string(REQUIRED_FIELD).required(REQUIRED_FIELD),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!addBotLoading) {
        setAddBotLoading(true);
        let res;
        if (botObj?.purpose === "edit") {
          res = await dispatch(editBot(selectedBot?._id, values));
        } else {
          res = await dispatch(addBot(values));
        }
        ToastContainer(res);
        if (res?.success) {
          resetForm();
          setQuillValue("");
          setModalShow(false);
          setSelectedWidgets([]);
          resetStateValues();
        }
        setAddBotLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("widgets", selectedWidgets);
  }, [selectedWidgets]);

  const handleEditorChange = (html) => {
    if (html === "<p><br></p>") {
      setQuillValue("");
      formik.setFieldValue("bot_message", "");
      return;
    } else {
      setQuillValue(html);
      formik.setFieldValue("bot_message", html);
    }
  };

  const handleEditorBlur = () => {
    formik.setFieldTouched("bot_message", true);
  };

  const resetStateValues = () => {
    setQuillValue("");
    setAddBotLoading(false);
    setSelectedWidgets([]);
    setSelectedBot({});
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          id="modal-bot"
          tabIndex="-1"
          aria-labelledby="modal-bot"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-body py-0">
              <div className="profile modal-gx-n">
                <div className="profile-img text-primary rounded-top-xl">
                  <img src={CardHeader} width="100%" />
                  <div className="position-absolute top-0 start-0 p-5">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setModalShow(false)}
                      className="btn-close btn-close-white btn-close-arrow opacity-100"
                    />
                  </div>
                </div>
                <div className="profile-body">
                  <div className="avatar avatar-lg">
                    <span className="avatar-text bg-primary">
                      <i className="fal fa-user-robot fa-lg"></i>
                    </span>
                  </div>
                  <h4 className="fw-bold mb-1">
                    {botObj?.purpose === "edit" ? "Edit" : "New"} Chat Bot Entry
                  </h4>
                  {botObj?.purpose === "addNew" && (
                    <p style={{ fontSize: "16px" }}>
                      Setup chat bot entries to help your customers.
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-py">
                <div className="card-body">
                  <form autoComplete="off">
                    <div className="row gy-6">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="bot-keyword"
                            placeholder="Enter Keyword(s) to fire the chat"
                            name="keyword"
                            value={formik.values.keyword}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="bot-keyword">Keyword</label>
                          <div id="keyword-help" className="form-text">
                            Insert keywords comma separated: hello,hi,good
                            morning.
                          </div>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.keyword && formik.errors.keyword}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="bot-title"
                            placeholder="Enter title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="bot-title">Enter Title</label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.title && formik.errors.title}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className={
                            localStorage.getItem(`theme`) === "light"
                              ? "form-floating light"
                              : "form-floating dark"
                          }
                        >
                          <ReactQuill
                            placeholder="Bot Message"
                            quillValue={quillValue}
                            handleChange={handleEditorChange}
                            handleEditorBlur={handleEditorBlur}
                          />
                          <div id="bot-help" className="form-text">
                            You can use placeholders: %clientname, %systememail
                          </div>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.bot_message &&
                              formik.errors.bot_message}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <MultipleSelect
                          label="Chat Widget"
                          allItems={allWidgets}
                          selectedItems={selectedWidgets}
                          setSelectedItems={setSelectedWidgets}
                        />
                        <small style={{ color: "red", marginLeft: "10px" }}>
                          {formik.touched.widgets && formik.errors.widgets}
                        </small>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="bot-department"
                            name="department"
                            value={formik.values.department}
                            onChange={formik.handleChange}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {allDepartments?.map((x, index) => (
                              <option key={index} value={x?._id}>
                                {x?.title}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="bot-department">Department</label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.department &&
                              formik.errors.department}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="bot-language"
                            name="language"
                            value={formik.values.language}
                            onChange={formik.handleChange}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="en">EN</option>
                            <option value="de">DE</option>
                            <option value="es">ES</option>
                          </select>
                          <label htmlFor="bot-language">Language</label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.language && formik.errors.language}
                          </small>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-8">
                <div className="d-flex align-items-center mb-4 px-6">
                  <small className="text-muted me-auto">Options</small>
                </div>
                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-lock"></i>
                        </div>
                      </div>
                      <div className="col">
                        <h5>Active</h5>
                        <p>Is this bot active?</p>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="bot-options-1"
                            name="is_engage_active"
                            value={formik.values.is_engage_active}
                            onChange={formik.handleChange}
                            checked={formik.values.is_engage_active}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="bot-options-1"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-user"></i>
                        </div>
                      </div>
                      <div className="col">
                        <h5>Connect with FAQ</h5>
                        <p>Should this bot answer search through the FAQ?</p>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="bot-options-2"
                            name="is_connect_faq"
                            value={formik.values.is_connect_faq}
                            onChange={formik.handleChange}
                            checked={formik.values.is_connect_faq}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="bot-options-2"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-robot"></i>
                        </div>
                      </div>
                      <div className="col">
                        <h5>Show suggestions</h5>
                        <p>Should show keywords of this bot as suggestions?</p>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="bot-options-3"
                            name="is_show_keywords"
                            value={formik.values.is_show_keywords}
                            onChange={formik.handleChange}
                            checked={formik.values.is_show_keywords}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="bot-options-3"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr-bold modal-gx-n my-0" />
              <div className="modal-py">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  style={{ height: "56px" }}
                >
                  {addBotLoading ? (
                    <div
                      className="spinner-grow text-light"
                      role="status"
                      style={{
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {botObj?.purpose === "edit" ? "Update" : "Save"}
                      <span className="icon ms-auto">
                        <i className="fal fa-chevron-right"></i>
                      </span>
                    </React.Fragment>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default BotModal;

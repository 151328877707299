import React, { memo } from "react";

const Search = ({
  placeholder,
  GetAllUsers,
  handleSearch,
  handleNewChat,
  value,
}) => {
  return (
    <form action="#">
      <div className="input-group">
        <div className="input-group-text">
          <div className="icon icon-lg">
            <i className="fal fa-search"></i>
          </div>
        </div>
        <input
          type="text"
          className="form-control form-control-lg ps-0"
          placeholder={placeholder}
          aria-label="Search for messages or users..."
          value={value}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      {/* {GetAllUsers?.length > 0 ? (
        <div className="searchBar_Dropdown">
          {GetAllUsers?.map((item, index) => (
            <p
              key={index}
              className="searchBar_Dropdown_para"
              onClick={() => handleNewChat(item)}
            >
              {item?.email}
            </p>
          ))}
        </div>
      ) : (
        ''
      )} */}
    </form>
  );
};
export default memo(Search);

import React, { memo, useState, useMemo } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import CardHeader from "../../assets/img/core/card-header.png";
import { GROUP_NOTIFICATIONS, USER_ROLES } from "../../constant";
import { ClientCheckbox, ToastContainer } from "../../components";
import {
  createGroup,
  editGroup,
  sendMessageInGroup,
  UpdateSpecificGroupMessage,
} from "../../redux/actions/groupChatAction";

const REQUIRED_FIELD = "Required field";

const GroupChatModal = ({ setModalShow, modalShow }) => {
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupImage, setGroupImage] = useState(null);
  const [showGroupImage, setShowGroupImage] = useState("");
  const [loading, setLoading] = useState(false);

  const { allUsers } = useSelector((state) => state.userReducer);
  const { socket } = useSelector((state) => state.socketReducer);
  const { userData } = useSelector((state) => state.userReducer);
  const { allOperators } = useSelector((state) => state.operatorReducer);
  const { purpose, groupById } = useSelector(
    (state) => state?.groupChatReducer
  );

  const dispatch = useDispatch();
  const data = new FormData();

  /////////////////////////////////////////////////////////////
  // Getting Clients
  let clientsArray = allUsers?.data?.filter((x) => {
    return x?.user_role === `client`;
  });

  const parsedDataClients = useMemo(() => {
    const parsedDataClients = clientsArray?.reduce((r, name) => {
      const l = name?.name[0];
      if (!r[l]) r[l] = [name];
      else r[l]?.push(name);
      return r;
    }, {});
    let sorted = [];
    if (parsedDataClients) {
      sorted = Object?.entries(parsedDataClients)?.sort(([a], [b]) =>
        a?.localeCompare(b)
      );
    }
    return sorted;
  }, [allUsers, modalShow]);
  // Getting Clients
  /////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////
  // Getting Operators
  let filteredArray = [];
  filteredArray = allOperators?.filter((x) => {
    return x?.user_role !== USER_ROLES?.EDITOR;
  });

  let removeGroupAdmin = filteredArray?.filter((x) => {
    return x?._id !== userData?.data?._id;
  });

  const parsedData = useMemo(() => {
    const parsedData = removeGroupAdmin?.reduce((r, name) => {
      const l = name?.name[0];
      if (!r[l]) r[l] = [name];
      else r[l]?.push(name);
      return r;
    }, {});
    let sorted = [];
    if (parsedData) {
      sorted = Object?.entries(parsedData)?.sort(([a], [b]) =>
        a?.localeCompare(b)
      );
    }
    return sorted;
  }, [allOperators?.length, removeGroupAdmin, modalShow]);
  // Getting Operators
  /////////////////////////////////////////////////////////////

  let INITIAL_VALUE = {
    group_title: "",
    purpose: "",
    is_private: false,
  };
  let editedValues = {
    group_title: groupById?.group_title,
    purpose: groupById?.purpose,
    is_private: groupById?.is_private,
    is_mute: groupById?.is_mute,
  };

  const validationSchema = yup.object({
    group_title: yup
      .string(REQUIRED_FIELD)
      .trim(" ")
      .min(5, "Title must be 5 characters long")
      .required(REQUIRED_FIELD),
    purpose: yup
      .string(REQUIRED_FIELD)
      .trim(" ")
      .min(5, "Purpose must be 5 characters long")
      .required(REQUIRED_FIELD),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: purpose === "edit" ? editedValues : INITIAL_VALUE,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let res = {};
      if (!loading) {
        setLoading(true);
        if (groupImage !== null) data.append("group_image", groupImage);
        if (purpose === "edit")
          data.append("is_mute", JSON.stringify(groupById?.is_mute));
        data.append("group_title", values.group_title);
        data.append("purpose", values.purpose);
        data.append("is_private", values.is_private);
        let createGroupMessage = GROUP_NOTIFICATIONS?.group_created
          ?.replace("%admin", userData?.data?.name)
          ?.replace("%groupname", values?.group_title);
        data.append("text", createGroupMessage);
        data.append("type", "notification");
        if (purpose === "edit") {
          data.append(
            "group_members",
            JSON.stringify(groupById?.group_members)
          );
          res = await dispatch(editGroup(data, groupById?._id));
          if (res?.success) {
            let newMessage = {
              conversationId: groupById?._id,
              createdAt: Date.now(),
              receiverId: groupById?.group_members,
              senderId: userData?.data,
              text: GROUP_NOTIFICATIONS?.edit_group_info?.replace(
                "%admin",
                groupById?.group_admin?.name
              ),
              updatedAt: Date.now(),
              type: "notification",
            };
            // Send msg API
            dispatch(
              sendMessageInGroup(
                {
                  type: "notification",
                  text: GROUP_NOTIFICATIONS?.edit_group_info?.replace(
                    "%admin",
                    groupById?.group_admin?.name
                  ),
                },
                groupById?._id
              )
            );
            //Socket for group inside notification
            if (Object.keys(socket).length !== 0) {
              socket.emit(`updateGroupData`, {
                data: res?.data,
                groupId: res?.data?._id,
              });
              socket.emit(`addGroupMessage`, {
                data: newMessage,
                groupId: groupById?._id,
              });
            }
            // Update msg array
            dispatch(UpdateSpecificGroupMessage(newMessage, groupById?._id));
          }
        } else {
          data.append("group_members", JSON.stringify(groupMembers));
          res = await dispatch(createGroup(data));
        }
        if (res?.success) {
          resetForm();
          setModalShow(false);
          socket.emit(`createNewGroup`, res?.data);
        }
        setLoading(false);
        setGroupMembers([]);
        setGroupImage(null);
        setShowGroupImage("");
        ToastContainer(res);
      }
    },
  });
  const handleUserProfilePhoto = async (e) => {
    setGroupImage(e.target.files[0]);
    setShowGroupImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleModal = () => {
    setModalShow(false);
    setShowGroupImage("");
  };
  return (
    <React.Fragment>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          id="modal-groupchat"
          tabIndex="-1"
          aria-labelledby="modal-groupchat"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-header">
              <i
                style={{ cursor: "pointer" }}
                onClick={handleModal}
                className="btn-close btn-close-arrow opacity-100"
              />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body py-0">
                <div className="modal-py">
                  <div className="mb-6">
                    <ul className="nav nav-pills nav-justified" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="pill"
                          href="#create-chat-info"
                          role="tab"
                          aria-controls="create-chat-info"
                        >
                          Details
                        </a>
                      </li>
                      {purpose === "addNew" ? (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="pill"
                            href="#create-chat-members"
                            role="tab"
                            aria-controls="create-chat-members"
                          >
                            Members
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                  <div className="tab-content" role="tablist">
                    <div
                      className="tab-pane fade show active"
                      id="create-chat-info"
                      role="tabpanel"
                    >
                      <div className="card border-0">
                        <div className="profile">
                          <div className="profile-img text-primary rounded-top">
                            <img src={CardHeader} width="100%" />
                          </div>
                          <div className="profile-body p-0">
                            <div className="avatar avatar-lg">
                              <span className="avatar-text bg-primary">
                                {purpose === "edit" ? (
                                  <React.Fragment>
                                    {showGroupImage !== "" ? (
                                      <img
                                        className="avatar-img round_avatar"
                                        src={showGroupImage}
                                        alt="#"
                                      />
                                    ) : groupById?.group_image ? (
                                      <img
                                        className="avatar-img round_avatar"
                                        src={groupById?.group_image}
                                        alt="#"
                                      />
                                    ) : (
                                      <span className="avatar-text">
                                        {groupById?.group_title
                                          ?.charAt(0)
                                          ?.toUpperCase()}
                                      </span>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {showGroupImage !== "" ? (
                                      <img
                                        className="avatar-img round_avatar"
                                        src={showGroupImage}
                                        alt="#"
                                      />
                                    ) : (
                                      <i className="fal fa-image"></i>
                                    )}
                                  </React.Fragment>
                                )}
                              </span>
                              <input
                                id="uploadchatimg"
                                className="d-none"
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleUserProfilePhoto(e)}
                              />
                              <label
                                className="stretched-label mb-0"
                                htmlFor="uploadchatimg"
                              >
                                <div className="badge badge-lg badge-circle bg-primary border-outline position-absolute bottom-0 end-0">
                                  <i className="fal fa-plus"></i>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <div className="row gy-6">
                              <div className="col-12">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Enter a chat name"
                                    name="group_title"
                                    value={formik.values.group_title}
                                    onChange={formik.handleChange}
                                  />
                                  <label htmlFor="floatingInput">
                                    Enter group name
                                  </label>
                                  <small
                                    style={{ color: "red", marginLeft: "10px" }}
                                  >
                                    {formik.touched.group_title &&
                                      formik.errors.group_title}
                                  </small>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-floating">
                                  <textarea
                                    className="form-control"
                                    placeholder="Description"
                                    id="floatingTextarea"
                                    rows="8"
                                    data-autosize="true"
                                    style={{ minHeight: "100px" }}
                                    name="purpose"
                                    value={formik.values.purpose}
                                    onChange={formik.handleChange}
                                  ></textarea>
                                  <label htmlFor="floatingTextarea">
                                    What's your purpose?
                                  </label>
                                  <small
                                    style={{ color: "red", marginLeft: "10px" }}
                                  >
                                    {formik.touched.purpose &&
                                      formik.errors.purpose}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center mt-4 px-6">
                        <small className="text-muted me-auto">
                          Enter chat name and add an optional photo.
                        </small>
                      </div> */}
                      <div className="mt-8">
                        <div className="d-flex align-items-center mb-4 px-6">
                          <small className="text-muted me-auto">Options</small>
                        </div>
                        <div className="card border-0">
                          <div className="card-body">
                            <div className="row gx-5">
                              <div className="col-auto">
                                <div className="btn btn-sm btn-icon btn-dark">
                                  <i className="fal fa-lock"></i>
                                </div>
                              </div>
                              <div className="col">
                                <h5>Make Private</h5>
                                <p>Can only be viewed by invites</p>
                              </div>
                              <div className="col-auto align-self-center">
                                <div className="form-check form-switch ps-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="new-chat-options-1"
                                    name="is_private"
                                    value={formik.values.is_private}
                                    onChange={formik.handleChange}
                                    checked={formik.values.is_private}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="new-chat-options-1"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="create-chat-members"
                      role="tabpanel"
                    >
                      <ul
                        className="nav nav-pills nav-justified"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="pill"
                            href="#add-new-group-operators"
                            role="tab"
                            aria-controls="add-new-group-operators"
                            aria-selected="true"
                          >
                            Operators
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="pill"
                            href="#add-new-group-clients"
                            role="tab"
                            aria-controls="add-new-group-clients"
                            aria-selected="true"
                          >
                            Clients
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content py-2" role="tablist">
                        <div
                          className="tab-pane fade show active"
                          id="add-new-group-operators"
                          role="tabpanel"
                        >
                          <nav>
                            {parsedData?.length > 0 ? (
                              parsedData?.map(([alphabet, user]) => (
                                <React.Fragment>
                                  <div className="my-5">
                                    <small className="text-uppercase text-muted">
                                      {alphabet}
                                    </small>
                                  </div>
                                  {user?.map((item, index) => (
                                    <ClientCheckbox
                                      key={index}
                                      data={item}
                                      setGroupMembers={setGroupMembers}
                                      groupMembers={groupMembers}
                                    />
                                  ))}
                                </React.Fragment>
                              ))
                            ) : (
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: 15,
                                  fontSize: 16,
                                }}
                              >
                                No user found
                              </p>
                            )}
                          </nav>
                        </div>
                        <div
                          className="tab-pane fade show"
                          id="add-new-group-clients"
                          role="tabpanel"
                        >
                          <nav>
                            {parsedDataClients?.length > 0 ? (
                              parsedDataClients?.map(([alphabet, user]) => (
                                <React.Fragment>
                                  <div className="my-5">
                                    <small className="text-uppercase text-muted">
                                      {alphabet}
                                    </small>
                                  </div>
                                  {user?.map((item, index) => (
                                    <ClientCheckbox
                                      key={index}
                                      data={item}
                                      setGroupMembers={setGroupMembers}
                                      groupMembers={groupMembers}
                                    />
                                  ))}
                                </React.Fragment>
                              ))
                            ) : (
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: 15,
                                  fontSize: 16,
                                }}
                              >
                                No user found
                              </p>
                            )}
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hr-bold modal-gx-n my-0" />
                <div className="modal-py">
                  <button
                    type="submit"
                    style={{ height: "56px" }}
                    className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  >
                    {loading ? (
                      <div
                        className="spinner-grow text-light"
                        role="status"
                        style={{
                          backgroundColor: "white",
                          width: "15px",
                          height: "15px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        {purpose === "edit" ? "Update" : "Save"}
                        <span className="icon ms-auto">
                          <i className="fal fa-chevron-right"></i>
                        </span>
                      </React.Fragment>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default memo(GroupChatModal);

// import React from "react";
// import { useLocation, useNavigate } from "react-router-dom";

// const BackToCall = () => {
//   const { pathname } = useLocation();
//   const navigate = useNavigate();

//   const handleBackToCall = () => {
//     if (sessionStorage.getItem("call-type") == "meeting") {
//       navigate(`/meeting/${sessionStorage.getItem("meeting-conversationId")}`);
//     } else if (sessionStorage.getItem("call-type") == "direct") {
//       navigate(`/call/${sessionStorage.getItem("recipientId")}`);
//     }
//   };
//   return (
//     <React.Fragment>
//       {sessionStorage.getItem("streamCheck") &&
//       !pathname?.includes("call") &&
//       !pathname?.includes("meeting") ? (
//         <div
//           className="onHoverScale fade_in"
//           style={{
//             position: "absolute",
//             zIndex: 999999999,
//             top: 0,
//             right: 0,
//             width: 250,
//             height: 200,
//             backgroundColor:
//               localStorage.getItem("theme") === "light" ? "#fff" : "#1E2126",
//             boxShadow: "0px 0px 10px -5px black",
//             margin: 50,
//             borderRadius: 10,
//             paddingLeft: 20,
//             paddingRight: 20,
//             display: "grid",
//             alignItems: "center",
//             cursor: "pointer",
//           }}
//           onClick={handleBackToCall}
//         >
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               width: "100%",
//             }}
//           >
//             <p
//               style={{
//                 textAlign: "center",
//                 margin: 0,
//               }}
//             >
//               On going call
//               <span className="typing-dots">
//                 <span>.</span>
//                 <span>.</span>
//                 <span>.</span>
//               </span>
//             </p>
//             <div className="active-call" />
//           </div>
//           <div
//             style={{
//               width: 70,
//               height: 70,
//               backgroundColor: "#2787F5",
//               borderRadius: 100,
//               margin: "auto",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             {sessionStorage.getItem("streamCheck") === "v-o-i-c-e" && (
//               <i
//                 className="fal fa-phone"
//                 style={{ fontSize: 23, color: "white" }}
//               />
//             )}
//             {sessionStorage.getItem("streamCheck") === "s-t-r-e-a-m" && (
//               <i
//                 className="fal fa-video"
//                 style={{ fontSize: 23, color: "white" }}
//               />
//             )}
//           </div>
//           <p
//             className="onHoverUnderline"
//             style={{
//               textAlign: "center",
//             }}
//           >
//             Back to call
//           </p>
//         </div>
//       ) : null}
//     </React.Fragment>
//   );
// };

// export default BackToCall;

import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BackToCall = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const [position, setPosition] = useState({ x: 50, y: 50 }); // Default position
  const [isDragging, setIsDragging] = useState(false);

  // Load position from sessionStorage on component mount
  useEffect(() => {
    const savedPosition = sessionStorage.getItem("modalPosition");
    if (savedPosition) {
      setPosition(JSON.parse(savedPosition));
    }
  }, []);

  const handleBackToCall = () => {
    if (!isDragging) {
      if (sessionStorage.getItem("call-type") === "meeting") {
        navigate(
          `/meeting/${sessionStorage.getItem("meeting-conversationId")}`
        );
      } else if (sessionStorage.getItem("call-type") === "direct") {
        navigate(`/call/${sessionStorage.getItem("recipientId")}`);
      }
    }
  };

  const handleMouseDown = (e) => {
    const modal = modalRef.current;
    const shiftX = e.clientX - modal.getBoundingClientRect().left;
    const shiftY = e.clientY - modal.getBoundingClientRect().top;

    setIsDragging(false); // Reset drag state

    // Disable text selection on the page during dragging
    document.body.classList.add("no-select");

    const onMouseMove = (event) => {
      setIsDragging(true);
      const newX = event.clientX - shiftX;
      const newY = event.clientY - shiftY;

      // Get the screen boundaries
      const modalWidth = modal.offsetWidth;
      const modalHeight = modal.offsetHeight;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Ensure the modal stays within the screen boundaries with a 100px padding
      const boundedX = Math.min(
        Math.max(newX, 5), // Minimum x (left boundary)
        screenWidth - modalWidth - 5 // Maximum x (right boundary)
      );
      const boundedY = Math.min(
        Math.max(newY, 5), // Minimum y (top boundary)
        screenHeight - modalHeight - 5 // Maximum y (bottom boundary)
      );

      // Update the modal's position
      modal.style.left = `${boundedX}px`;
      modal.style.top = `${boundedY}px`;
    };

    const onMouseUp = () => {
      // Save final position to sessionStorage
      const modalBounds = modal.getBoundingClientRect();
      const finalPosition = { x: modalBounds.left, y: modalBounds.top };
      sessionStorage.setItem("modalPosition", JSON.stringify(finalPosition));

      // Cleanup event listeners and drag state
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.body.classList.remove("no-select"); // Re-enable text selection
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  return (
    <React.Fragment>
      {sessionStorage.getItem("streamCheck") &&
      !pathname?.includes("call") &&
      !pathname?.includes("meeting") ? (
        <div
          ref={modalRef}
          className="onHoverScale fade_in"
          style={{
            position: "absolute",
            zIndex: 999999999,
            top: position.y,
            left: position.x,
            width: 250,
            height: 200,
            backgroundColor:
              localStorage.getItem("theme") === "light" ? "#fff" : "#1E2126",
            boxShadow: "0px 0px 10px -5px black",
            borderRadius: 10,
            paddingLeft: 20,
            paddingRight: 20,
            display: "grid",
            alignItems: "center",
            cursor: "move",
          }}
          onMouseDown={handleMouseDown} // Attach drag event
          onClick={handleBackToCall} // Trigger navigation only on click
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p
              style={{
                textAlign: "center",
                margin: 0,
              }}
            >
              On going call
              <span className="typing-dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </p>
            <div className="active-call" />
          </div>
          <div
            style={{
              width: 70,
              height: 70,
              backgroundColor: "#2787F5",
              borderRadius: 100,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {sessionStorage.getItem("streamCheck") === "v-o-i-c-e" && (
              <i
                className="fal fa-phone"
                style={{ fontSize: 23, color: "white" }}
              />
            )}
            {sessionStorage.getItem("streamCheck") === "s-t-r-e-a-m" && (
              <i
                className="fal fa-video"
                style={{ fontSize: 23, color: "white" }}
              />
            )}
          </div>
          <p
            className="onHoverUnderline"
            style={{
              textAlign: "center",
            }}
          >
            Back to call
          </p>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default BackToCall;

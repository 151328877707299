import React, { useState, useEffect } from "react";

const MultipleSelect = ({
  count,
  label,
  allItems,
  allOperators,
  selectedItems,
  setSelectedItems,
}) => {
  const handleOption = (departmentId, isChecked) => {
    if (isChecked) {
      setSelectedItems((prevDepartments) => [...prevDepartments, departmentId]);
    } else {
      setSelectedItems((prevDepartments) =>
        prevDepartments?.filter((id) => id !== departmentId)
      );
    }
  };

  const handleSelectAll = () => {
    if (selectedItems?.length === allItems?.length) {
      setSelectedItems([]);
    } else {
      const allItemsIds = allItems?.map((item) => item?._id);
      setSelectedItems(allItemsIds);
    }
  };

  return (
    <React.Fragment>
      <div className="dropdown">
        <div
          className="w-100 form-select"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ textAlign: "left", height: 56 }}
        >
          {label}{" "}
          {selectedItems?.length ? (
            <small>( {selectedItems?.length} )</small>
          ) : null}
        </div>

        <ul
          className="dropdown-menu w-100"
          aria-labelledby="dropdownMenuButton"
        >
          <li>
            <input
              style={{ marginRight: 20 }}
              className="form-check-input"
              type="checkbox"
              id="selectAll"
              checked={selectedItems?.length === allItems?.length}
              onChange={handleSelectAll}
            />
            <label className="form-check-label" htmlFor="selectAll">
              Select All
            </label>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          {allItems?.map((item) => (
            <li key={item?._id}>
              <input
                style={{ marginRight: 20 }}
                className="form-check-input"
                type="checkbox"
                id={`option${item?._id}`}
                checked={selectedItems?.includes(item?._id)}
                onChange={(e) => handleOption(item?._id, e?.target?.checked)}
              />
              <label
                className="form-check-label"
                htmlFor={`option${item?._id}`}
              >
                {item?.title ? item?.title : item?.name}{" "}
                {count && (
                  <small
                    style={{
                      color: "rgba(255,255,255,0.35)",
                      marginLeft: 5,
                    }}
                  >
                    ({" "}
                    {
                      allOperators?.filter((operator) => {
                        return operator?.department?.includes(item?._id);
                      })?.length
                    }{" "}
                    Operators )
                  </small>
                )}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default MultipleSelect;

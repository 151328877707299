export const TYPES = {
  /////////////////////_USER_AUTH_///////////////////////////
  USER_LOGOUT: `USER_LOGOUT`,
  DELETE_RATING: `DELETE_RATING`,
  USER_AUTH_FAIL: `USER_AUTH_FAIL`,
  CLEAR_AUTH_DATA: `CLEAR_AUTH_DATA`,
  USER_AUTH_LOADING: `USER_AUTH_LOADING`,
  USER_AUTH_SUCCESS: `USER_AUTH_SUCCESS`,
  USER_ONLINE_STATUS: `USER_ONLINE_STATUS`,
  UPDATE_USER_PROFILE: `UPDATE_USER_PROFILE`,
  UPDATE_CONVERSATION: `UPDATE_CONVERSATION`,
  UPDATE_DELETE_STATUS: `UPDATE_DELETE_STATUS`,
  UPDATE_CONVERSATION_BY_INDEX: `UPDATE_CONVERSATION_BY_INDEX`,
  UPDATE_CONVERSATION_WITH_SOCKET: `UPDATE_CONVERSATION_WITH_SOCKET`,

  /////////////////////_ALL_USERS_///////////////////////////
  GET_ALL_USERS_FAIL: `GET_ALL_USERS_FAIL`,
  UPDATE_ONLINE_STATUS: `UPDATE_ONLINE_STATUS`,
  GET_ALL_USERS_LOADING: `GET_ALL_USERS_LOADING`,
  GET_ALL_USERS_SUCCESS: `GET_ALL_USERS_SUCCESS`,
  UPDATE_ALL_USERS_ARRAY: `UPDATE_ALL_USERS_ARRAY`,

  /////////////////////_USER_BY_ID_///////////////////////////
  GET_USER_BY_ID_SUCCESS: `GET_USER_BY_ID_SUCCESS`,
  UPDATE_USER_BY_ID: `UPDATE_USER_BY_ID`,

  /////////////////////_CONNECT_SOCKET_///////////////////////////
  CONNECT_SOCKET_SUCCESS: `CONNECT_SOCKET_SUCCESS`,

  /////////////////////_UPDATE_USER_PHOTO_///////////////////////////
  UPDATE_USER_PHOTO_LOADING: `UPDATE_USER_PHOTO_LOADING`,
  UPDATE_USER_PHOTO_SUCCESS: `UPDATE_USER_PHOTO_SUCCESS`,
  UPDATE_USER_PHOTO_FAIL: `UPDATE_USER_PHOTO_FAIL`,

  /////////////////////_ALL_NOTIFICATIONS_///////////////////////////
  GET_ALL_NOTIFICATIONS_LOADING: `GET_ALL_NOTIFICATIONS_LOADING`,
  GET_ALL_NOTIFICATIONS_SUCCESS: `GET_ALL_NOTIFICATIONS_SUCCESS`,
  GET_ALL_NOTIFICATIONS_FAIL: `GET_ALL_NOTIFICATIONS_FAIL`,
  UPDATE_ALL_NOTIFICATIONS: `UPDATE_ALL_NOTIFICATIONS`,
  ADD_NEW_NOTIFICATION: `ADD_NEW_NOTIFICATION`,
  CLEAR_ALL_NOTIFICATIONS: `CLEAR_ALL_NOTIFICATIONS`,
  MESSAGE_NOTIFICATION: `MESSAGE_NOTIFICATION`,

  /////////////////////_GET_ALL_FAQ_///////////////////////////
  GET_ALL_FAQ_LOADING: `GET_ALL_FAQ_LOADING`,
  GET_ALL_FAQ_SUCCESS: `GET_ALL_FAQ_SUCCESS`,
  GET_ALL_FAQ_FAIL: `GET_ALL_FAQ_FAIL`,
  UPDATE_ALL_FAQ: `UPDATE_ALL_FAQ`,
  DELETE_FAQ_BY_ID: `DELETE_FAQ_BY_ID`,
  UPDATE_FAQ_STATUS: `UPDATE_FAQ_STATUS`,

  /////////////////////_SET_FAQ_ID_///////////////////////////
  SET_FAQ_ID: `SET_FAQ_ID`,

  /////////////////////_GET_ALL_BOTS_///////////////////////////
  GET_ALL_BOTS_LOADING: `GET_ALL_BOTS_LOADING`,
  GET_ALL_BOTS_SUCCESS: `GET_ALL_BOTS_SUCCESS`,
  GET_ALL_BOTS_FAIL: `GET_ALL_BOTS_FAIL`,
  UPDATE_ALL_BOTS: `UPDATE_ALL_BOTS`,
  UPDATE_DELETED_BOT: `UPDATE_DELETED_BOT`,
  UPDATE_BOT_STATUS: `UPDATE_BOT_STATUS`,

  /////////////////////_SET_BOT_ID_///////////////////////////
  SET_BOT_ID: `SET_BOT_ID`,

  /////////////////////_GET_ALL_RESPONSE_///////////////////////////
  GET_ALL_RESPONSE_LOADING: `GET_ALL_RESPONSE_LOADING`,
  GET_ALL_RESPONSE_SUCCESS: `GET_ALL_RESPONSE_SUCCESS`,
  GET_ALL_RESPONSE_FAIL: `GET_ALL_RESPONSE_FAIL`,
  UPDATE_ALL_RESPONSE: `UPDATE_ALL_RESPONSE`,
  UPDATE_RESPONSE_STATUS: `UPDATE_RESPONSE_STATUS`,
  DELETE_RESPONSE: `DELETE_RESPONSE`,

  /////////////////////_ADD_RESPONSE_///////////////////////////
  ADD_RESPONSE_LOADING: `ADD_RESPONSE_LOADING`,
  ADD_RESPONSE_SUCCESS: `ADD_RESPONSE_SUCCESS`,
  ADD_RESPONSE_FAIL: `ADD_RESPONSE_FAIL`,

  /////////////////////_SET_RESPONSE_ID_///////////////////////////
  SET_RESPONSE_ID: `SET_RESPONSE_ID`,

  /////////////////////_GET_ALL_OPERATORS_///////////////////////////
  GET_ALL_OPERATORS_LOADING: `GET_ALL_OPERATORS_LOADING`,
  GET_ALL_OPERATORS_SUCCESS: `GET_ALL_OPERATORS_SUCCESS`,
  GET_ALL_OPERATORS_FAIL: `GET_ALL_OPERATORS_FAIL`,
  UPDATE_ALL_OPERATORS: `UPDATE_ALL_OPERATORS`,
  DELETE_OPERATOR: `DELETE_OPERATOR`,
  UPDATE_SINGLE_OPERATOR: `UPDATE_SINGLE_OPERATOR`,

  /////////////////////_SET_OPERATOR_ID_///////////////////////////
  SET_OPERATOR_ID: `SET_OPERATOR_ID`,

  /////////////////////_GET_ALL_DEPARTMENTS_///////////////////////////

  GET_ALL_DEPARTMENTS_LOADING: `GET_ALL_DEPARTMENTS_LOADING`,
  GET_ALL_DEPARTMENTS_SUCCESS: `GET_ALL_DEPARTMENTS_SUCCESS`,
  GET_ALL_DEPARTMENTS_FAIL: `GET_ALL_DEPARTMENTS_FAIL`,
  UPDATE_DEPARTMENTS: `UPDATE_DEPARTMENTS`,
  UPDATE_DEPARTMENTS_DETAILS: `UPDATE_DEPARTMENTS_DETAILS`,
  UPDATE_DELETED_DEPARTMENT: `UPDATE_DELETED_DEPARTMENT`,

  /////////////////////_SET_DEPARTMENT_ID_///////////////////////////

  SET_DEPARTMENT_ID: `SET_DEPARTMENT_ID`,

  /////////////////////_GET_ALL_ENGAGE_ENTRY_///////////////////////////
  GET_ALL_ENGAGE_ENTRY_LOADING: `GET_ALL_ENGAGE_ENTRY_LOADING`,
  GET_ALL_ENGAGE_ENTRY_SUCCESS: `GET_ALL_ENGAGE_ENTRY_SUCCESS`,
  GET_ALL_ENGAGE_ENTRY_FAIL: `GET_ALL_ENGAGE_ENTRY_FAIL`,
  UPDATE_ENGAGE_ENTRY: `UPDATE_ENGAGE_ENTRY`,
  UPDATE_ENGAGE_ENTRY_DETAILS: `UPDATE_ENGAGE_ENTRY_DETAILS`,
  UPDATE_DELETED_ENTRY_DETAILS: `UPDATE_DELETED_DEPARTMENT`,

  ///////////////////////////Set Engage ID ///////////////////
  SET_ENGAGE_ID: `SET_ENGAGE_ID`,

  ///////////////////////SET_CLIENT_ID///////////////////////
  SET_CLIENT_ID: `SET_CLIENT_ID`,

  ///////////////////////UPDATE_CLIENT_BY_ID///////////////////////
  UPDATE_CLIENT_BY_ID: `UPDATE_CLIENT_BY_ID`,

  ///////////////////////CURRENT_SESSION///////////////////////
  CURRENT_SESSION: `CURRENT_SESSION`,

  ///////////////////////GET_APPLICATION///////////////////////
  GET_APPLICATION_LOADING: `GET_APPLICATION_LOADING`,
  GET_APPLICATION_SUCCESS: `GET_APPLICATION_SUCCESS`,
  GET_APPLICATION_ERROR: `GET_APPLICATION_ERROR`,
  APP_STATICS: `APP_STATICS`,
  GET_ONLINE_USER: `GET_ONLINE_USER`,
  SET_ACTIVE_TAB: `SET_ACTIVE_TAB`,
  UPDATE_APP_LOGO: `UPDATE_APP_LOGO`,

  ///////////////////////CORFIRMATION_MODAL///////////////////////
  SHOW_MODAL: `SHOW_MODAL`,

  ///////////////////////TYPING///////////////////////
  IS_TYPING: `IS_TYPING`,
  IS_TYPING_IN_GROUP: `IS_TYPING_IN_GROUP`,

  /////////////////////_GET_ALL_GROUPS_///////////////////////////
  GET_ALL_GROUPS_LOADING: `GET_ALL_GROUPS_LOADING`,
  GET_ALL_GROUPS_SUCCESS: `GET_ALL_GROUPS_SUCCESS`,
  GET_ALL_GROUPS_FAIL: `GET_ALL_GROUPS_FAIL`,
  ADD_NEW_GROUP: `ADD_NEW_GROUP`,
  UPDATE_SPECIFIC_GROUPS_MESSAGES: `UPDATE_SPECIFIC_GROUPS_MESSAGES`,
  GROUP_BY_ID: `GROUP_BY_ID`,
  SET_GROUP_PURPOSE: `SET_GROUP_PURPOSE`,
  DELETE_GROUP: `DELETE_GROUP`,
  GROUP_BY_ID_LOADING: `GROUP_BY_ID_LOADING`,
  GROUP_BY_ID_SUCCESS: `GROUP_BY_ID_SUCCESS`,
  GROUP_BY_ID_FAIL: `GROUP_BY_ID_FAIL`,
  EDIT_GROUP_BY_ID: `EDIT_GROUP_BY_ID`,
  DELETE_GROUP_MESSAGE: `DELETE_GROUP_MESSAGE`,
  UPDATE_MESSAGE_BY_INDEX: `UPDATE_MESSAGE_BY_INDEX`,
  UPDATE_MESSAGE_WITH_SOCKET: `UPDATE_MESSAGE_WITH_SOCKET`,
  ADD_NEW_MESSAGE: `ADD_NEW_MESSAGE`,
  SET_USER_ID: `SET_USER_ID`,
  ADD_UNREAD_MESSAGE: `ADD_UNREAD_MESSAGE`,

  /////////////////////_GET_ALL_GROUPS_///////////////////////////
  SHOW_PROGRESS: `SHOW_PROGRESS`,

  /////////////////////_GET_ALL_CONVERSATIONS_///////////////////////////
  GET_ALL_CONVERSATIONS_LOADING: `GET_ALL_CONVERSATIONS_LOADING`,
  GET_ALL_CONVERSATIONS: `GET_ALL_CONVERSATIONS`,
  CREATE_NEW_CONVERSATION: `CREATE_NEW_CONVERSATION`,
  DELETE_CONVERSATION: `DELETE_CONVERSATION`,
  APPLICATION_CONVERSATIONS_LOADING: `APPLICATION_CONVERSATIONS_LOADING`,
  APPLICATION_CONVERSATIONS: `APPLICATION_CONVERSATIONS`,

  /////////////////////_CONVERSATION_BY_ID_///////////////////////////
  GET_CONVERSATION_BY_ID: `GET_CONVERSATION_BY_ID`,
  PUSH_MESSAGE: `PUSH_MESSAGE`,
  ADD_ID_TO_MESSAGE: `ADD_ID_TO_MESSAGE`,
  UPDATE_CONVERSATION_STATUS: `UPDATE_CONVERSATION_STATUS`,
  IMAGE_PREVIEW: `IMAGE_PREVIEW`,
  UPDATE_CONVERSATION_BY_ID: `UPDATE_CONVERSATION_BY_ID`,

  /////////////////////_VIDEO_CALL_///////////////////////////
  VIDEO_CALL_STATUS: `VIDEO_CALL_STATUS`,
  VIDEO_CALL_SIGNALS: `VIDEO_CALL_SIGNALS`,
  GROUP_VIDEO_CALL_STATUS: `GROUP_VIDEO_CALL_STATUS`,
  GROUP_VIDEO_CALL_SIGNALS: `GROUP_VIDEO_CALL_SIGNALS`,
  SET_CALL_DATA: `SET_CALL_DATA`,
  SET_GROUP_VIDEO_CALL_DATA: `SET_GROUP_VIDEO_CALL_DATA`,
  VIDEO_SIDEBAR: `VIDEO_SIDEBAR`,

  /////////////////////_WIDGET_///////////////////////////
  READ_WIDGET: `READ_WIDGET`,
  SET_WIDGET_ID: `SET_WIDGET_ID`,
  UPDATE_WIDGET: `UPDATE_WIDGET`,
  DELETE_WIDGET: `DELETE_WIDGET`,
  CREATE_WIDGET: `CREATE_NEW_WIDGET`,

  /////////////////////_SHARE_FILE_MODAL_///////////////////////////
  SHARE_FILE_DATA: `SHARE_FILE_DATA`,
  UPDATE_SHARED_FILE: `UPDATE_SHARED_FILE`,
  IS_SHARE_FILE_MODAL: `IS_SHARE_FILE_MODAL`,

  /////////////////////_EMAIL_TEMPLATE_///////////////////////////
  GET_ALL_EMAIL_TEMPLATES_LOADING: `GET_ALL_EMAIL_TEMPLATES_LOADING`,
  GET_ALL_EMAIL_TEMPLATES_SUCCESS: `GET_ALL_EMAIL_TEMPLATES_SUCCESS`,
  GET_ALL_EMAIL_TEMPLATES_FAIL: `GET_ALL_EMAIL_TEMPLATES_FAIL`,
  CREATE_NEW_TEMPLATE_SUCCESS: `CREATE_NEW_TEMPLATE_SUCCESS`,
  DELETE_TEMPLATE_SUCCESS: `DELETE_TEMPLATE_SUCCESS`,
  UPDATE_TEMPLATE_SUCCESS: `UPDATE_TEMPLATE_SUCCESS`,

  /////////////////////_APP_THEME_///////////////////////////
  SET_APP_THEME: `SET_APP_THEME`,
};

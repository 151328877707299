import React, { memo, useState, useEffect } from "react";
import CardHeader from "../../assets/img/core/card-header.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  createOperator,
  updateOperatorProfile,
  updateOperator,
} from "../../redux/actions/operatorAction";
import { ToastContainer, MultipleSelect } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { USER_ROLES } from "../../constant";

let REQUIRED_FIELD = `Field is required`;

const OperatorModal = ({ setModalShow, modalShow }) => {
  const [imgUrl, setImgUrl] = useState("");
  const [operatorImage, setOperatorImage] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState({});

  const dispatch = useDispatch();
  const data = new FormData();

  const { allOperators, operatorObj } = useSelector(
    (state) => state.operatorReducer
  );
  const { allDepartments } = useSelector((state) => state.departmentReducer);
  const { appSettings } = useSelector((state) => state.applicationReducer);
  const { userData } = useSelector((state) => state.userReducer);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedOperator?.name || "",
      email: selectedOperator?.email || "",
      phone: selectedOperator?.phone || "",
      user_role: selectedOperator?.user_role || "",
      department: selectedOperator?.department || [],
      language: selectedOperator?.language || "",
      password: "",
      confirm_password: "",
      application_id: appSettings?._id || "",
    },
    validationSchema: yup.object({
      name: yup
        .string(REQUIRED_FIELD)
        .min(5, "Name must be 5 characters long")
        .required(REQUIRED_FIELD),
      email: yup
        .string(REQUIRED_FIELD)
        .min(5, "Email must be 5 characters long")
        .email("Enter a valid email")
        .required(REQUIRED_FIELD),
      phone: yup
        .string(REQUIRED_FIELD)
        .min(2, "Phone must be 2 characters long")
        .required(REQUIRED_FIELD),
      password: yup
        .string(REQUIRED_FIELD)
        .test(
          "password-conditional-validation",
          "Password must be at least 8 characters long",
          function (value) {
            const { confirm_password } = this.parent; // Access sibling field
            if (operatorObj?.purpose === "edit") {
              // If both fields are empty, ignore validation
              return !value && !confirm_password ? true : value?.length >= 8;
            }
            return value?.length >= 8;
          }
        ),
      confirm_password: yup
        .string(REQUIRED_FIELD)
        .test(
          "confirm-password-conditional-validation",
          "Passwords must match",
          function (value) {
            const { password } = this.parent; // Access sibling field
            if (operatorObj?.purpose === "edit") {
              // If both fields are empty, ignore validation
              return !value && !password ? true : value === password;
            }
            return value === password;
          }
        ),
      user_role: yup.string(REQUIRED_FIELD).required(REQUIRED_FIELD),
      department: yup
        .array(REQUIRED_FIELD)
        .of(yup.string().required(REQUIRED_FIELD))
        .min(1, REQUIRED_FIELD)
        .required(REQUIRED_FIELD),
      language: yup.string(REQUIRED_FIELD).required(REQUIRED_FIELD),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (operatorImage !== null) values.picture = operatorImage;
      if (!loading) {
        setLoading(true);
        if (operatorObj?.purpose === `edit`) {
          const {
            password,
            confirm_password,
            application_id,
            ...filteredValues
          } = values;
          if (formik.values?.password !== "") {
            filteredValues.password = formik.values?.password;
          }
          if (filteredValues?.email === selectedOperator?.email)
            delete filteredValues?.email;
          if (filteredValues?.phone === selectedOperator?.phone)
            delete filteredValues?.phone;
          for (const key in filteredValues) {
            data.append(key, filteredValues[key]);
          }
          let res = await dispatch(
            updateOperator(data, operatorObj?.operatorId)
          );
          setLoading(false);
          ToastContainer(res);
          if (!res?.success) return;
        } else {
          const { confirm_password, ...filteredValues } = values;
          for (const key in filteredValues) {
            data.append(key, filteredValues[key]);
          }
          let res = await dispatch(createOperator(data));
          setLoading(false);
          ToastContainer(res);
          if (!res?.success) return;
        }
        setModalShow(false);
        resetForm();
        resetStateValues();
      }
    },
  });

  useEffect(() => {
    setSelectedOperator(
      allOperators?.find((x) => x?._id === operatorObj?.operatorId) ?? {}
    );
  }, [operatorObj, allOperators]);

  useEffect(() => {
    setSelectedDepartments(selectedOperator?.department ?? []);
  }, [selectedOperator, operatorObj]);

  useEffect(() => {
    formik.setFieldValue("department", JSON.stringify(selectedDepartments));
  }, [selectedDepartments]);

  const selectOperatorImage = (e) => {
    setOperatorImage(e.target.files[0]);
    setImgUrl(window.URL.createObjectURL(e.target.files[0]));
  };

  const resetStateValues = () => {
    setImgUrl("");
    setOperatorImage(null);
    setPasswordVisible(false);
    setSelectedDepartments([]);
    setLoading(false);
    setConfirmPasswordVisible(false);
    setSelectedOperator({});
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          id="modal-operator"
          tabIndex="-1"
          aria-labelledby="modal-operator"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-body py-0">
              <div className="profile modal-gx-n">
                <div className="profile-img text-primary rounded-top-xl">
                  <img src={CardHeader} width="100%" />
                  <div className="position-absolute top-0 start-0 p-5">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setModalShow(false)}
                      className="btn-close btn-close-white btn-close-arrow opacity-100"
                    />
                  </div>
                </div>
                <div className="profile-body p-0">
                  <div className="avatar avatar-lg">
                    {operatorObj?.purpose === "edit" ? (
                      <React.Fragment>
                        {imgUrl === "" ? (
                          <React.Fragment>
                            {selectedOperator?.picture ? (
                              <img
                                className="avatar-img round_avatar"
                                src={selectedOperator?.picture}
                                alt="#"
                              />
                            ) : (
                              <span className="avatar-text">
                                {selectedOperator?.name
                                  ?.charAt(0)
                                  .toUpperCase()}
                              </span>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {imgUrl ? (
                              <img
                                className="avatar-img round_avatar"
                                src={imgUrl}
                                alt="#"
                              />
                            ) : (
                              <span className="avatar-text bg-primary">
                                <i className="fal fa-image"></i>
                              </span>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {imgUrl ? (
                          <img
                            className="avatar-img round_avatar"
                            src={imgUrl}
                            alt="#"
                          />
                        ) : (
                          <span className="avatar-text bg-primary">
                            <i className="fal fa-image"></i>
                          </span>
                        )}
                      </React.Fragment>
                    )}
                    <div className="badge badge-lg badge-circle bg-primary border-outline position-absolute bottom-0 end-0">
                      <i className="fal fa-plus"></i>
                    </div>
                    <input
                      id="upload-operator-img"
                      className="d-none"
                      type="file"
                      accept="image/*"
                      onChange={(e) => selectOperatorImage(e)}
                    />
                    <label
                      className="stretched-label mb-0"
                      htmlFor="upload-operator-img"
                    ></label>
                  </div>
                  <h4 className="fw-bold mb-1">
                    {operatorObj?.purpose === "edit" ? "Edit" : "Create new"}{" "}
                    Operator
                  </h4>
                  {operatorObj?.purpose === "addNew" && (
                    <p style={{ fontSize: "16px" }}>
                      Create new operator for your business.
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-py">
                <div className="card-body">
                  <form>
                    <div className="row gy-6">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="test"
                            className="form-control form-control-lg"
                            id="operator-name"
                            placeholder="Full Name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                          <label
                            htmlFor="operator-name"
                            className="form-label text-muted"
                          >
                            Name
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.name && formik.errors.name}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            disabled={
                              userData?.data?.user_role ===
                              USER_ROLES?.SUPER_OPERATOR
                                ? false
                                : operatorObj?.purpose === "edit"
                            }
                            type="email"
                            className="form-control form-control-lg"
                            id="operator-email"
                            placeholder="name@example.com"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                          <label
                            htmlFor="operator-email"
                            className="form-label text-muted"
                          >
                            E-mail
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.email && formik.errors.email}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            disabled={
                              userData?.data?.user_role ===
                              USER_ROLES?.SUPER_OPERATOR
                                ? false
                                : operatorObj?.purpose === "edit"
                            }
                            type="number"
                            className="form-control form-control-lg hide_arrows"
                            id="operator-phone_number"
                            placeholder="00000000000"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                          />
                          <label
                            htmlFor="operator-phone_number"
                            className="form-label text-muted"
                          >
                            Phone number
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.phone && formik.errors.phone}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="userGroup"
                            name="user_role"
                            value={formik.values.user_role}
                            onChange={formik.handleChange}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="operator">Operator</option>
                            <option value="junior_operator">
                              Junior Operator
                            </option>
                            <option value="editor" defaultValue>
                              Editor
                            </option>
                          </select>
                          <label htmlFor="userGroup">User Group</label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.user_role &&
                              formik.errors.user_role}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <MultipleSelect
                          label={`Select Department`}
                          allItems={allDepartments}
                          selectedItems={selectedDepartments}
                          setSelectedItems={setSelectedDepartments}
                        />
                        <small style={{ color: "red", marginLeft: "10px" }}>
                          {formik.touched.department &&
                            formik.errors.department}
                        </small>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="operator-language"
                            name="language"
                            value={formik.values.language}
                            onChange={formik.handleChange}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="en">EN</option>
                            <option value="de">DE</option>
                            <option value="es">ES</option>
                          </select>
                          <label
                            htmlFor="operator-language"
                            className="form-label text-muted"
                          >
                            Language
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.language && formik.errors.language}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className="form-floating"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor:
                              localStorage.getItem("theme") === "light"
                                ? "#Ebf1f7"
                                : "#1E2126",
                            borderRadius: "0.6rem",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type={passwordVisible ? "text" : "password"}
                            className="form-control signup_inputs"
                            id="signup-password"
                            placeholder="Password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="signup-password">Password</label>
                          <FontAwesomeIcon
                            icon={passwordVisible ? faEye : faEyeSlash}
                            style={{ cursor: "pointer" }}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          />
                        </div>
                        <small style={{ color: "red", marginLeft: "10px" }}>
                          {formik.touched.password && formik.errors.password}
                        </small>
                      </div>
                      <div className="col-12">
                        <div
                          className="form-floating"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor:
                              localStorage.getItem("theme") === "light"
                                ? "#Ebf1f7"
                                : "#1E2126",
                            borderRadius: "0.6rem",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            className="form-control signup_inputs"
                            id="signup-confirm-password"
                            placeholder="Confirn Password"
                            name="confirm_password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="signup-confirm-password">
                            Confirm Password
                          </label>
                          <FontAwesomeIcon
                            icon={confirmPasswordVisible ? faEye : faEyeSlash}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }
                          />
                        </div>
                        <small style={{ color: "red", marginLeft: "10px" }}>
                          {formik.touched.confirm_password &&
                            formik.errors.confirm_password}
                        </small>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <hr className="hr-bold modal-gx-n my-0" />
              <div className="modal-py">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  style={{ height: "56px" }}
                >
                  {loading ? (
                    <div
                      className="spinner-grow text-light"
                      role="status"
                      style={{
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {operatorObj?.purpose === "edit" ? "Update" : "Save"}
                      <span className="icon ms-auto">
                        <i className="fal fa-chevron-right"></i>
                      </span>
                    </React.Fragment>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default memo(OperatorModal);

import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { axiosClient } from "../../utils";

const REQUIRED_FIELD = "Required field";

const ResetPasssword = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string(REQUIRED_FIELD)
      .email("Enter a valid email")
      .trim(" ")
      .required(REQUIRED_FIELD),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let a = 12345;
      if (!loading) {
        setLoading(true);
        await axiosClient()
          .post(`/user/forgetPasswordEmail`, values)
          .then((res) => {
            notifySuccess(res?.data?.message);
            setTimeout(() => {
              handleNavigate(`/user-authentification/${values?.email}`);
            }, 2000);
          })
          .catch((err) => {
            notifyError(err?.response?.data?.message);
          });
        setLoading(false);
      }
    },
  });
  const handleNavigate = (link) => {
    navigate(link);
  };
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  return (
    <div className="container">
      <div className="row align-items-center justify-content-center min-vh-100 gx-0">
        <div className="col-12 col-md-5 col-lg-4">
          <div className="card card-shadow border-0">
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      <h3 className="fw-bold mb-2">Password Reset</h3>
                      <p>Enter your email to reset password</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="resetpassword-email"
                        placeholder="Email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="resetpassword-email">Email</label>
                      <small style={{ color: "red", marginLeft: "10px" }}>
                        {formik.touched.email && formik.errors.email}
                      </small>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-block btn-lg btn-primary w-100"
                      type="submit"
                    >
                      {loading ? (
                        <div
                          className="spinner-grow text-light"
                          role="status"
                          style={{
                            backgroundColor: "white",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Send Code"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="text-center mt-8">
            <p>
              Already have an account?{" "}
              <a
                style={{ cursor: "pointer", color: "#2787F5" }}
                onClick={() => handleNavigate("/")}
              >
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};
export default memo(ResetPasssword);

import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { images } from "../utils/fileTypes";

const ImageSelector = ({ setDocument, setFileType, selectedFileTypes }) => {
  let commonFiles =
    selectedFileTypes?.filter((file) => {
      return images?.includes(file);
    }) ?? [];
  const getFileTypes = () => {
    let filesObj = {};

    for (let index = 0; index < commonFiles?.length; index++) {
      filesObj[`image/${commonFiles[index]}`] = [`.${commonFiles[index]}`];
    }

    return filesObj;
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: getFileTypes(),
  });

  useEffect(() => {
    if (acceptedFiles?.length > 0) setDocument(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <React.Fragment>
      <div {...getRootProps({ className: "" })} hidden>
        <input {...getInputProps()} hidden />
      </div>
      {commonFiles?.length ? (
        <i
          className="fal fa-image"
          onClick={() => {
            setFileType("images");
            open();
          }}
        />
      ) : null}
    </React.Fragment>
  );
};
export default ImageSelector;

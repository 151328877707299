import React, { memo, useState, useEffect } from "react";
import CardHeader from "../../assets/img/core/card-header.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, ReactQuill } from "../../components";
import {
  addNewDepartment,
  editDepartment,
} from "../../redux/actions/departmentAction";
import Modal from "react-bootstrap/Modal";

let REQUIRED_FILED = `Required field`;

let INITIAL_VALUES = {
  title: "",
  description: "",
  email: "",
  language: "en",
  is_department_active: false,
  is_for_clients: false,
};

const DepartmentModal = ({ setDepartmentModalShow, departmentModalShow }) => {
  const [addDepartmentLoading, setAddDepartmentLoading] = useState(false);
  const [quillValue, setQuillValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (quillValue === "<p><br></p>") setQuillValue("");
  }, [quillValue]);

  const { departmentObj, appDepartments } = useSelector(
    (state) => state.departmentReducer
  );
  const { appSettings } = useSelector((state) => state.applicationReducer);

  let selectedDepartment = appDepartments?.find((x) => {
    return x?._id === departmentObj?.departmentId;
  });

  useEffect(() => {
    if (departmentObj?.purpose === "edit") {
      setQuillValue(selectedDepartment?.description);
    }
  }, [selectedDepartment, departmentObj]);
  useEffect(() => {
    if (departmentObj?.purpose !== "edit") {
      setQuillValue("");
    }
  }, [departmentObj]);

  let editedValues = {
    title: selectedDepartment?.title,
    description: selectedDepartment?.description,
    email: selectedDepartment?.email,
    language: selectedDepartment?.language,
    is_department_active: selectedDepartment?.is_department_active,
    is_for_clients: selectedDepartment?.is_for_clients,
  };

  const validationSchema = yup.object({
    title: yup
      .string(REQUIRED_FILED)
      .min(2, "Title must be 2 characters long")
      .required(REQUIRED_FILED),
    description: yup
      .string(REQUIRED_FILED)
      .min(12, "Description must be 5 characters long")
      .required(REQUIRED_FILED),
    email: yup
      .string(REQUIRED_FILED)
      .required(REQUIRED_FILED)
      .min(3, "Email must be 3 characters long")
      .email("Enter a valid email"),
    language: yup.string(REQUIRED_FILED).required(REQUIRED_FILED),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      departmentObj?.purpose === `edit` ? editedValues : INITIAL_VALUES,
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      if (!addDepartmentLoading) {
        setAddDepartmentLoading(true);
        let res = "";
        if (departmentObj?.purpose === `edit`) {
          res = await dispatch(
            editDepartment(departmentObj?.departmentId, values)
          );
        } else {
          values.application_id = appSettings?._id;
          res = await dispatch(addNewDepartment(values));
        }
        ToastContainer(res);
        if (res?.success) {
          resetForm();
          setDepartmentModalShow(false);
        }
        setAddDepartmentLoading(false);
      }
    },
  });

  const handleEditorChange = (html) => {
    if (html === "<p><br></p>") {
      setQuillValue("");
      formik.setFieldValue("description", "");
      return;
    } else {
      setQuillValue(html);
      formik.setFieldValue("description", html);
    }
  };
  const handleEditorBlur = () => {
    formik.setFieldTouched("description", true);
  };

  return (
    <Modal
      show={departmentModalShow}
      onHide={() => setDepartmentModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          id="modal-department"
          tabIndex="-1"
          aria-labelledby="modal-department"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-body py-0">
              <div className="profile modal-gx-n">
                <div className="profile-img text-primary rounded-top-xl">
                  <img alt="" src={CardHeader} width="100%" />
                  <div className="position-absolute top-0 start-0 p-5">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setDepartmentModalShow(false)}
                      className="btn-close btn-close-white btn-close-arrow opacity-100"
                    />
                  </div>
                </div>
                <div className="profile-body">
                  <div className="avatar avatar-lg">
                    <span className="avatar-text bg-primary">
                      <i className="fal fa-home"></i>
                    </span>
                  </div>
                  <h4 className="fw-bold mb-1">
                    {departmentObj?.purpose === "edit" ? "Edit" : "Create New"}{" "}
                    Department
                  </h4>
                  {departmentObj?.purpose === "addNew" && (
                    <p style={{ fontSize: "16px" }}>
                      Create a new department for your website visitors.
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-py">
                <div className="card-body">
                  <form autoComplete="off">
                    <div className="row gy-6">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="department-title"
                            placeholder="Enter title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="department-title">Enter Title</label>
                          <small
                            style={{
                              color: "red",
                              marginLeft: "10px",
                            }}
                          >
                            {formik.touched.title && formik.errors.title}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className={
                            localStorage.getItem(`theme`) === "light"
                              ? "form-floating light"
                              : "form-floating dark"
                          }
                        >
                          {/* <textarea
                            className="form-control"
                            placeholder="Description"
                            id="department-description"
                            rows="8"
                            data-autosize="true"
                            style={{ minHeight: '100px' }}
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                          ></textarea>
                          <label htmlFor="department-description">
                            Enter a Description
                          </label> */}
                          <ReactQuill
                            placeholder="Enter a Description"
                            quillValue={quillValue}
                            handleChange={handleEditorChange}
                            handleEditorBlur={handleEditorBlur}
                          />
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.description &&
                              formik.errors.description}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            disabled={departmentObj?.purpose === `edit`}
                            type="email"
                            className="form-control"
                            id="department-email"
                            placeholder="Email address"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="department-email">Email</label>
                          <small
                            style={{
                              color: "red",
                              marginLeft: "10px",
                            }}
                          >
                            {formik.touched.email && formik.errors.email}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="language"
                            name="language"
                            value={formik.values.language}
                            onChange={formik.handleChange}
                          >
                            <option value="en" defaultValue>
                              EN
                            </option>
                            <option value="de">DE</option>
                            <option value="es">ES</option>
                          </select>
                          <label htmlFor="language">Language</label>
                          <small
                            style={{
                              color: "red",
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-8">
                <div className="d-flex align-items-center mb-4 px-6">
                  <small className="text-muted me-auto">Options</small>
                </div>
                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-lock"></i>
                        </div>
                      </div>
                      <div className="col">
                        <h5>Active</h5>

                        <p>Is the department active?</p>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="department-options-1"
                            name="is_department_active"
                            value={formik.values.is_department_active}
                            onChange={formik.handleChange}
                            checked={formik.values.is_department_active}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="department-options-1"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-user"></i>
                        </div>
                      </div>

                      <div className="col">
                        <h5>Clients</h5>

                        <p>Only for clients?</p>
                      </div>

                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="department-options-2"
                            name="is_for_clients"
                            value={formik.values.is_for_clients}
                            onChange={formik.handleChange}
                            checked={formik.values.is_for_clients}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="department-options-2"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="hr-bold modal-gx-n my-0" />

              <div className="modal-py">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  style={{ height: "56px" }}
                >
                  {addDepartmentLoading ? (
                    <div
                      className="spinner-grow text-light"
                      role="status"
                      style={{
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {departmentObj?.purpose === `edit` ? "Update" : "Save"}
                      <span className="icon ms-auto">
                        <i className="fal fa-chevron-right"></i>
                      </span>
                    </React.Fragment>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default memo(DepartmentModal);

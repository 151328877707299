import React, { memo, useState } from "react";
import * as yup from "yup";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { axiosClient } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

const Authentification = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const { param } = useParams();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .trim(" ")
      .required("Email is required"),
    code: yup.string("Enter OTP that you received").required("OTP is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    confirmPassword: yup
      .string("Enter your confirmed password")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: param,
      code: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!loading) {
        setloading(true);
        const newPassword = {
          email: values?.email,
          resetToken: values?.code,
          password: values?.password,
        };
        await axiosClient()
          .post(`/user/updatePassword`, newPassword)
          .then((res) => {
            if (res?.data?.success) {
              Cookies.set(`accessToken`, res?.data?.token);
            }
            notifySuccess(res?.data?.message);
            setTimeout(() => {
              handleNavigate("/");
            }, 1500);
          })
          .catch((err) => {
            notifyError(err?.response?.data?.message);
          });
        setloading(false);
      }
    },
  });
  const handleNavigate = (link) => {
    navigate(link);
  };
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  return (
    <div className="container">
      <div className="row align-items-center justify-content-center min-vh-100 gx-0">
        <div className="col-12 col-md-5 col-lg-4">
          <div className="card card-shadow border-0">
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      <h3 className="fw-bold mb-2">
                        Two Factor Authentication
                      </h3>
                      <p>Enter the unique code that you received</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="twofactor-email"
                        placeholder="Email"
                        name="email"
                        label="Email"
                        value={param}
                        disabled
                        onChange={formik.handleChange}
                        style={{ cursor: "not-allowed" }}
                      />
                      <label htmlFor="twofactor-email">Email</label>
                      <small style={{ color: "red", marginLeft: "10px" }}>
                        {formik.touched.email && formik.errors.email}
                      </small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="twofactor-code"
                        placeholder="Code"
                        name="code"
                        label="Code"
                        value={formik.values.code}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="twofactor-code">Code</label>
                      <small style={{ color: "red", marginLeft: "10px" }}>
                        {formik.touched.code && formik.errors.code}
                      </small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="form-floating"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          localStorage.getItem("theme") === "light"
                            ? "#Ebf1f7"
                            : "#1E2126",
                        borderRadius: "0.6rem",
                        paddingRight: "10px",
                      }}
                    >
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control custom-input"
                        id="signin-password"
                        placeholder="Password"
                        name="password"
                        label="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signin-password">Password</label>
                      {passwordVisible ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ cursor: "pointer" }}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{ cursor: "pointer" }}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      )}
                    </div>
                    <small
                      style={{
                        color: "red",
                        marginLeft: "10px",
                      }}
                    >
                      {formik.touched.password && formik.errors.password}
                    </small>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="twofactor-confirmPassword"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        label="Confirm Password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="twofactor-code">Confirm Password</label>
                      <small style={{ color: "red", marginLeft: "10px" }}>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword}
                      </small>
                    </div>
                  </div> */}
                  <div className="col-12">
                    <div
                      className="form-floating"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          localStorage.getItem("theme") === "light"
                            ? "#Ebf1f7"
                            : "#1E2126",
                        borderRadius: "0.6rem",
                        paddingRight: "10px",
                      }}
                    >
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        className="form-control custom-input"
                        id="signin-confirm-password"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        label="Password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signin-confirm-password">
                        Confirm Password
                      </label>
                      {confirmPasswordVisible ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setConfirmPasswordVisible(!confirmPasswordVisible)
                          }
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setConfirmPasswordVisible(!confirmPasswordVisible)
                          }
                        />
                      )}
                    </div>
                    <small
                      style={{
                        color: "red",
                        marginLeft: "10px",
                      }}
                    >
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword}
                    </small>
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-block btn-lg btn-primary w-100"
                      type="submit"
                    >
                      {loading ? (
                        <div
                          className="spinner-grow text-light"
                          role="status"
                          style={{
                            backgroundColor: "white",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="text-center mt-8">
            <p>
              You did not receive a code?{" "}
              <a
                style={{ cursor: "pointer", color: "#2787F5" }}
                onClick={() => handleNavigate("/authentication/reset-password")}
              >
                Try again
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};
export default memo(Authentification);

import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

const ImageSelector = ({ setDocument, setFileType, selectedFileTypes }) => {
  const getFileTypes = () => {
    let filesObj = {};

    for (let index = 0; index < selectedFileTypes?.length; index++) {
      filesObj[`image/${selectedFileTypes[index]}`] = [
        `.${selectedFileTypes[index]}`,
      ];
    }

    return filesObj;
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: getFileTypes(),
  });

  useEffect(() => {
    if (acceptedFiles?.length > 0) setDocument(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <React.Fragment>
      <div {...getRootProps({ className: "dropzone" })} hidden>
        <input {...getInputProps()} hidden />
      </div>
      {selectedFileTypes?.length ? (
        <i
          className="fal fa-paperclip"
          onClick={() => {
            setFileType("files");
            open();
          }}
        />
      ) : null}
    </React.Fragment>
  );
};
export default ImageSelector;

import React, { memo, useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { USER_ROLES } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { createClient } from "../../redux/actions/userAction";
import CardHeader from "../../assets/img/core/card-header.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, MultipleSelect } from "../../components";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { updateClientProfile } from "../../redux/actions/clientAction";

const REQUIRED_FIELD = "Required field";

const ClientModal = ({ setModalShow, modalShow }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [addClientLoading, setAddClientLoading] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});

  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.userReducer);
  const { allDepartments } = useSelector((state) => state.departmentReducer);
  const { clientObj, allUsers } = useSelector((state) => state.userReducer);
  const { appSettings } = useSelector((state) => state.applicationReducer);

  useEffect(() => {
    setSelectedClient(
      allUsers?.data?.find((x) => {
        return x?._id === clientObj?.clientId;
      }) ?? {}
    );
  }, [allUsers, clientObj, modalShow]);

  useEffect(() => {
    setSelectedDepartments(selectedClient?.department ?? []);
  }, [selectedClient, clientObj]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedClient?.name || "",
      email: selectedClient?.email || "",
      phone: selectedClient?.phone || "",
      password: "",
      department: selectedClient?.department || [],
      language: selectedClient?.language || "",
      invitation_message: selectedClient?.invitation_message || "",
      is_send_mail: selectedClient?.is_send_mail || false,
      is_account_active: selectedClient?.is_account_active || false,
      user_role: "client",
      application_id: appSettings?._id || "",
    },
    validationSchema: yup.object({
      name: yup
        .string(REQUIRED_FIELD)
        .min(5, "Name must be 5 characters long")
        .required(REQUIRED_FIELD),
      email: yup
        .string(REQUIRED_FIELD)
        .email("Enter a valid email")
        .min(5, "Email must be 5 characters long")
        .required(REQUIRED_FIELD),
      phone: yup
        .string(REQUIRED_FIELD)
        .min(8, "Phone must be 8 characters long")
        .required(REQUIRED_FIELD),
      password: yup
        .string(REQUIRED_FIELD)
        .test(
          "password-conditional-validation",
          "Password must be at least 8 characters long",
          function (value) {
            if (clientObj?.purpose === "edit") {
              return !value || value?.length >= 8;
            }
            return value?.length >= 8;
          }
        ),
      department: yup
        .array(REQUIRED_FIELD)
        .of(yup.string().required(REQUIRED_FIELD))
        .min(1, REQUIRED_FIELD)
        .required(REQUIRED_FIELD),
      language: yup.string(REQUIRED_FIELD).required(REQUIRED_FIELD),
      invitation_message: yup
        .string(REQUIRED_FIELD)
        .min(5, "Message must be 5 characters long")
        .required(REQUIRED_FIELD),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!addClientLoading) {
        setAddClientLoading(true);
        if (clientObj?.purpose === "edit") {
          const { password, ...filteredValues } = values;
          if (formik.values?.password !== "") {
            filteredValues.password = formik.values?.password;
          }
          if (filteredValues?.email === selectedClient?.email)
            delete filteredValues?.email;
          if (filteredValues?.phone === selectedClient?.phone)
            delete filteredValues?.phone;
          let res = await dispatch(
            updateClientProfile(filteredValues, selectedClient?._id)
          );
          setAddClientLoading(false);
          ToastContainer(res);
          if (!res?.success) return;
        } else {
          let res = await dispatch(createClient(values));
          setAddClientLoading(false);
          ToastContainer(res);
          if (!res?.success) return;
        }
        setModalShow(false);
        resetForm();
        resetStateValues();
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("department", selectedDepartments);
  }, [selectedDepartments]);

  const resetStateValues = () => {
    setPasswordVisible(false);
    setAddClientLoading(false);
    setSelectedDepartments([]);
    setSelectedClient({});
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          id="modal-invite"
          tabIndex="-1"
          aria-labelledby="modal-invite"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-body py-0">
              <div className="profile modal-gx-n">
                <div className="profile-img text-primary rounded-top-xl">
                  <img src={CardHeader} width="100%" />
                  <div className="position-absolute top-0 start-0 p-5">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setModalShow(false)}
                      className="btn-close btn-close-white btn-close-arrow opacity-100"
                    />
                  </div>
                </div>
                <div className="profile-body p-0">
                  <div className="avatar avatar-lg">
                    <span className="avatar-text bg-primary">
                      {clientObj?.purpose === "edit" ? (
                        selectedClient?.picture ? (
                          <img
                            className="avatar-img round_avatar"
                            src={selectedClient?.picture}
                            alt="#"
                          />
                        ) : (
                          <span className="avatar-text">
                            {selectedClient?.name?.charAt(0).toUpperCase()}
                          </span>
                        )
                      ) : (
                        <i className="fal fa-user"></i>
                      )}
                    </span>
                  </div>
                  <h4 className="fw-bold mb-1">
                    {clientObj?.purpose === "edit"
                      ? `Edit ${selectedClient?.name}`
                      : "Create new Client"}
                  </h4>
                  {clientObj?.purpose === "addNew" && (
                    <p style={{ fontSize: "16px" }}>
                      Create new client and send invitation.
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-py">
                <div className="card-body">
                  <form autoComplete="off">
                    <div className="row gy-6">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="invite-name"
                            label="name"
                            placeholder="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                          <label
                            htmlFor="invite-name"
                            className="form-label text-muted"
                          >
                            Name
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.name && formik.errors.name}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="invite-email"
                            placeholder="email"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            disabled={
                              userData?.data?.user_role ===
                              USER_ROLES?.SUPER_OPERATOR
                                ? false
                                : clientObj?.purpose === "edit"
                            }
                          />
                          <label
                            htmlFor="invite-email"
                            className="form-label text-muted"
                          >
                            E-mail
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.email && formik.errors.email}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="number"
                            className="form-control form-control-lg hide_arrows"
                            id="invite-phone"
                            label="phone"
                            placeholder="phone"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            disabled={
                              userData?.data?.user_role ===
                              USER_ROLES?.SUPER_OPERATOR
                                ? false
                                : clientObj?.purpose === "edit"
                            }
                          />
                          <label
                            htmlFor="invite-phone"
                            className="form-label text-muted"
                          >
                            Phone
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.phone && formik.errors.phone}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className="form-floating"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor:
                              localStorage.getItem("theme") === "light"
                                ? "#Ebf1f7"
                                : "#1E2126",
                            borderRadius: "0.6rem",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type={passwordVisible ? "text" : "password"}
                            className="form-control"
                            id="signin-password"
                            placeholder="Password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="signin-password">Password</label>
                          {passwordVisible ? (
                            <FontAwesomeIcon
                              icon={faEye}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            />
                          )}
                        </div>
                        <small style={{ color: "red", marginLeft: "10px" }}>
                          {formik.touched.password && formik.errors.password}
                        </small>
                      </div>
                      <div className="col-12">
                        <MultipleSelect
                          label="Select Department"
                          allItems={allDepartments}
                          selectedItems={selectedDepartments}
                          setSelectedItems={setSelectedDepartments}
                        />
                        <small style={{ color: "red", marginLeft: "10px" }}>
                          {formik.touched.department &&
                            formik.errors.department}
                        </small>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="invite-language"
                            name="language"
                            value={formik.values.language}
                            onChange={formik.handleChange}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="en">EN</option>
                            <option value="de">DE</option>
                            <option value="es">ES</option>
                          </select>
                          <label
                            htmlFor="invite-language"
                            className="form-label text-muted"
                          >
                            Language
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.language && formik.errors.language}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <textarea
                            className="form-control form-control-lg"
                            id="invite-message"
                            data-autosize="true"
                            style={{ minHeight: "120px" }}
                            placeholder="Custom message"
                            name="invitation_message"
                            value={formik.values.invitation_message}
                            onChange={formik.handleChange}
                          ></textarea>
                          <label
                            htmlFor="invite-message"
                            className="form-label text-muted"
                          >
                            Invitation Message
                          </label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.invitation_message &&
                              formik.errors.invitation_message}
                          </small>
                        </div>
                      </div>
                      {clientObj?.purpose === "addNew" && (
                        <div className="card border-0">
                          <div className="row gx-5">
                            <div className="col">
                              <h5>Want to send credintials to the client?</h5>
                            </div>
                            <div className="col-auto align-self-center">
                              <div className="form-check form-switch ps-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkbox-for-send-mail"
                                  name="is_send_mail"
                                  value={formik.values.is_send_mail}
                                  onChange={formik.handleChange}
                                  checked={formik.values.is_send_mail}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkbox-for-send-mail"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-8">
                <div className="d-flex align-items-center mb-4 px-6">
                  <small className="text-muted me-auto">Options</small>
                </div>
                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-lock"></i>
                        </div>
                      </div>
                      <div className="col">
                        <h5>Active Status</h5>
                        <p>Is this client active?</p>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="bot-options-1"
                            name="is_account_active"
                            value={formik.values.is_account_active}
                            onChange={formik.handleChange}
                            checked={formik.values.is_account_active}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="bot-options-1"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr-bold modal-gx-n my-0" />
              <div className="modal-py">
                <button
                  className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  type="submit"
                  style={{ height: "56px" }}
                >
                  {addClientLoading ? (
                    <div
                      className="spinner-grow text-light"
                      role="status"
                      style={{
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {clientObj?.purpose === "edit" ? "Update" : "Save"}
                      <span className="icon ms-auto">
                        <i className="fal fa-chevron-right"></i>
                      </span>
                    </React.Fragment>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default memo(ClientModal);

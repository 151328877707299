/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { ToastContainer as ToastContainerFun } from "../../components";
import { useAuth } from "../../hooks";
import { axiosClient } from "../../utils";
import { useFormik } from "formik";
import * as yup from "yup";

const REQUIRED_FIELD = "Required field";

const Signin = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [signInLoading, setSignInLoading] = useState({
    purpose: "",
    loading: false,
  });

  const navigate = useNavigate();
  const { login } = useAuth();
  const { session } = useSelector((state) => state.sessionReducer);

  const validationSchema = yup.object({
    email: yup
      .string(REQUIRED_FIELD)
      .email("Enter a valid email")
      .trim(" ")
      .min(5, "Email must be 5 characters long")
      .required(REQUIRED_FIELD),
    password: yup
      .string(REQUIRED_FIELD)
      .min(5, "Password must be 5 characters long")
      .required(REQUIRED_FIELD),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.email = values.email.trim();
      values.password = values.password.trim();
      session.id = crypto.randomUUID();
      localStorage.setItem(`sessionId`, session.id);
      if (signInLoading.purpose !== "signIn" && !signInLoading.loading) {
        setSignInLoading({
          purpose: "signIn",
          loading: true,
        });
        try {
          const res = await login(values.email, values.password, session);
          ToastContainerFun(res);
          setTimeout(() => {
            if (
              res?.success &&
              res?.message === "Verification Code Sent To Your Mail"
            ) {
              navigate(`/authentication/otp/${values.email}`);
            } else {
              navigate("/");
            }
            setSignInLoading({
              purpose: "",
              loading: false,
            });
          }, 500);
        } catch (err) {
          ToastContainerFun(err.response.data);
          setSignInLoading({
            purpose: "",
            loading: false,
          });
        }
      }
    },
  });

  const handleNavigate = (link) => {
    navigate(link);
  };

  return (
    <div className="container">
      <div
        className="row align-items-center justify-content-center vh-100 gx-0 hide-scrollbar"
        style={{ overflow: "auto" }}
      >
        <div className="col-12 col-md-5 col-lg-4">
          <div className="card card-shadow border-0">
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      <h3 className="fw-bold mb-2">Sign In</h3>
                      <p>Login to your account</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="email"
                        placeholder="Email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="email">Email / Phone number</label>
                      <small style={{ color: "red", marginLeft: "10px" }}>
                        {formik.touched.email && formik.errors.email}
                      </small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="form-floating"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          localStorage.getItem("theme") === "light"
                            ? "#Ebf1f7"
                            : "#1E2126",
                        borderRadius: "0.6rem",
                        paddingRight: "10px",
                      }}
                    >
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control custom-input"
                        id="signin-password"
                        placeholder="Password"
                        name="password"
                        label="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signin-password">Password</label>
                      {passwordVisible ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ cursor: "pointer" }}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{ cursor: "pointer" }}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      )}
                    </div>
                    <small
                      style={{
                        color: "red",
                        marginLeft: "10px",
                      }}
                    >
                      {formik.touched.password && formik.errors.password}
                    </small>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-block btn-lg btn-primary w-100"
                      type="submit"
                    >
                      {signInLoading.purpose === "signIn" &&
                      signInLoading.loading ? (
                        <div
                          className="spinner-grow text-light"
                          role="status"
                          style={{
                            backgroundColor: "white",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Sign In"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="text-center mt-8">
            {/* <p>
              Don't have an account yet?{" "}
              <a
                style={{ cursor: "pointer", color: "#2787F5" }}
                onClick={() => handleNavigate("/authentication/signup")}
              >
                Sign up
              </a>
            </p> */}
            <p>
              <a
                style={{ cursor: "pointer", color: "#2787F5" }}
                onClick={() => handleNavigate("/authentication/reset-password")}
              >
                Forgot Password?
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};
export default memo(Signin);
